export interface StaticImageData {
  src: string;
  height: number;
  width: number;
  blurDataURL?: string;
}

export interface StaticRequire {
  default: StaticImageData;
}

export type StaticImport = StaticRequire | StaticImageData;

export function isStaticRequire(src: StaticRequire | StaticImageData): src is StaticRequire {
  return (src as StaticRequire).default !== undefined;
}

export function isStaticImageData(src: StaticRequire | StaticImageData): src is StaticImageData {
  return (src as StaticImageData).src !== undefined;
}

/**
 * @link https://github.com/vercel/next.js/blob/canary/packages/next/client/image.tsx#L77
 */
export function isStaticImport(src: string | StaticImport): src is StaticImport {
  return typeof src === 'object' && (isStaticRequire(src as StaticImport) || isStaticImageData(src as StaticImport));
}
