import { makeAutoObservable, runInAction } from 'mobx';
import Router from 'next/router';

import { RootStore } from '@my/mobx-rootstore';
import { makePersistable } from '@my/mobx-persist';

import { callApi, ApiResponseData, LoginSession, CurrentUser } from '@/apis';
import { RoleCode } from '@/apis/types';
import LoadingStore from '@/stores/ui/loading/LoadingStore';

import { IMBaseStore } from '@/stores/IM/IMStore';
import { IMLoginOut } from '@shared/common/helper/IMSDK';

export type tableData = ApiResponseData<'/account/current-user', 'get'>;

/**
 * 登录已过期
 */
const LoginExpired = 494;
export class AuthStore {
  loadingStore: LoadingStore;
  /**
   * 登录会话
   */
  loginSession?: LoginSession = undefined;

  /**
   * 需要登录提示框
   */
  loginTipModal = {
    visible: false,
    loading: false,
  };
  /**
   * 需要异地登录提示框
   */
  yunUserId?: string = '';
  /**
   * 当前用户信息
   */
  currentUserData: { value: CurrentUser | undefined; loading: boolean } = {
    value: undefined,
    loading: false,
  };
  iMStore: IMBaseStore;
  private getCurrentUserPromise?: Promise<CurrentUser>;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    makePersistable(this, { name: 'authStore', properties: ['loginSession'] });
    this.loadingStore = rootStore.getStore(LoadingStore);
    this.iMStore = rootStore.getStore(IMBaseStore);
  }

  /**
   * 当前用户信息
   */
  get currentUser() {
    return this.currentUserData.value;
  }

  /**
   * 获取当前用户信息
   */
  getCurrentUser({ refresh }: { refresh?: boolean } = {}) {
    if (refresh || !this.getCurrentUserPromise) {
      this.getCurrentUserPromise = new Promise((resolve) => {
        (async () => {
          runInAction(() => {
            this.currentUserData.loading = true;
          });
          //
          const resp = await callApi('/account/current-user', 'get', {});
          console.log('res', resp);

          if (resp.error) {
            if (process.env.NODE_ENV !== 'production') console.error('获取当前用户失败');
            this.checkAuth(resp.error.errCode === LoginExpired);
            throw resp.error;
          }

          runInAction(() => {
            this.currentUserData.value = resp.data.user;
            this.currentUserData.loading = false;
            this.yunUserId = resp.data.user.yunUserId;
          });
          if (resp.data.user.roleCode === RoleCode.Doctor) {
            this.iMStore.loginIM();
          }
          return resp.data.user;
        })().then(resolve, () => {});
      });
    }

    return this.getCurrentUserPromise;
  }

  /**
   * 检查是否已经登录，未登录则提示要登录
   */
  checkAuth(isExpires?: boolean) {
    if (!this.loginSession || isExpires) {
      if (typeof window !== 'undefined') {
        this.showLoginTipModal();
      }
    }
  }

  /**
   * 弹出需要登录提示框
   */
  showLoginTipModal() {
    this.loginTipModal.visible = true;
  }

  /** 短信登录 */
  async phoneLogin({ userName, code, password }: { userName: string; code: string; password: string }) {
    let loginSession: ApiResponseData<'/account/login/with-mobile-code', 'post'>['loginSession'];

    const resp = await callApi('/account/login/with-mobile-code', 'post', {
      authRequired: false,
      data: {
        userName: userName,
        code: code,
        password: password,
        clientType: 'OPEN_PLATFORM',
      },
    });

    if (resp.error) return { error: resp.error };

    const { data } = resp;
    loginSession = data.loginSession;

    await this.loginSuccess({ loginSession });

    return { loginSession };
  }

  /** 33云登录 */
  async sansanyunLogin(loginSession: any) {
    await this.loginSuccess({ loginSession });
    return { loginSession };
  }
  /** 33云解绑 */
  // async jieBind() {
  //   console.log(123123, this.currentUserData.value?.yunUserId);
  //   const resp = await callApi('/san-san-yun/unbind/{yunUserId}', 'post', {
  //     urlParams: { yunUserId: this.currentUserData.value!.yunUserId },
  //   });

  //   if (resp.error) {
  //     message.error(resp.error);
  //     return {
  //       error: resp.error,
  //     };
  //   }
  //   message.success('解绑成功');
  //   runInAction(() => {
  //     this.yunUserId = undefined;
  //   });
  //   this.cleanAuthData();

  //   this.goToLogin();
  // }

  /**
   * 登录
   */
  async login({
    userName,
    password,
    code,
    uniqueId,
    picCode,
  }: {
    userName: string;
    password: string;
    code: string;
    uniqueId: string;
    picCode: string;
  }) {
    let loginSession: ApiResponseData<'/account/login', 'post'>['loginSession'];

    const resp = await callApi('/account/login', 'post', {
      authRequired: false,
      params: { uniqueId: uniqueId, picCode: picCode },
      data: {
        userName,
        password,
        code,
        clientType: 'OPEN_PLATFORM',
      },
    });

    if (resp.error) return { error: resp.error };

    const { data } = resp;
    loginSession = data.loginSession;
    await this.loginSuccess({ loginSession });

    return { loginSession };
  }

  /**
   * 登出
   */
  async logout() {
    this.loadingStore.show();
    const resp = await callApi('/account/logout', 'get', {
      mock: {
        delay: 1500,
      },
    });

    if (resp.error) {
      if (process.env.NODE_ENV !== 'production') console.error(resp.error);
    }
    // IM退出登录
    try {
      IMLoginOut();
    } catch (error) {
      console.error(error);
    }
    this.cleanAuthData();

    this.goToLogin();
  }

  /**
   * 通过code免登录
   */
  async codeLogin(loginSession: any) {
    await this.loginSuccess({ loginSession });
  }

  /**
   * 重定向为登录页面
   */
  redirectToLogin() {
    this.loginTipModal.loading = true;
    this.cleanAuthData();
    // TODO 将当前路由作为 redirectTo 参数
    this.goToLogin();
  }

  /**
   * 登录成功
   */
  private loginSuccess({ loginSession }: { loginSession: LoginSession }) {
    this.loginSession = loginSession;

    return Router.push('/workspace');
  }

  private goToLogin({ redirectTo }: { redirectTo?: string } = {}) {
    let href = `${Router.basePath}/login`;
    if (redirectTo) {
      href += `?redirectTo=${redirectTo}`;
    }
    window.location.href = href;
  }

  /**
   * 清除登录信息
   */
  private cleanAuthData() {
    this.loginSession = undefined;
  }
}
