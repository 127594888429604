import { ChatSDK } from 'tim-js-sdk';
import { getMethod } from '../utils';
import { GroupManager } from './GroupManager';

let groupManager: undefined | GroupManager;

export function initGroupManager(tim: ChatSDK) {
  groupManager = new GroupManager(tim);
}

function getGroupManager() {
  if (!groupManager) throw new Error('请先调用 initGroupManager');
  return groupManager;
}

export const getGroupProfile = getMethod(getGroupManager, 'getGroupProfile');
