import { RootStore } from '@my/mobx-rootstore';
import { makeAutoObservable } from 'mobx';

export interface Loading {
  id: string;
  close: () => void;
}

export class LoadingStore {
  loadingList: Loading[] = [];

  constructor(_rootStore: RootStore) {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this.loadingList.length > 0;
  }

  show() {
    const loading = {
      id: `${new Date().getTime()}${Math.random()}`,
      close: () => {
        this.close(loading.id);
      },
    };
    this.loadingList.push(loading);

    return loading;
  }

  close(id: string) {
    this.loadingList = this.loadingList.filter((item) => item.id !== id);
  }
}

export default LoadingStore;
