import type { AppProps } from 'next/app';
import Head from 'next/head';

import { ConfigProvider } from 'antd';
// 由于 antd 组件的默认文案是英文，所以需要修改为中文
import zhCN from 'antd/lib/locale/zh_CN';

// store
import { RootStoreProvider } from '@my/mobx-rootstore';
import { rootStore } from '@/stores';

// components
import Title from '@/components/Title';
import AppLoading from './_app/components/AppLoading';
import CheckAuth from './_app/components/CheckAuth';
import Loading from './_app/components/Loading';
import LoginTipModal from './_app/components/LoginTipModal';

import './_app/dayjs';

// global css
import '@/styles/globals.scss';

function MyApp({ Component, pageProps }: AppProps<any>) {
  return (
    <ConfigProvider locale={zhCN}>
      <div style={{ overflow: 'hidden' }}>
        <RootStoreProvider store={rootStore} hydrationData={pageProps.hydrationData}>
          <Head>
            <link key="icon" rel="icon" href="/favicon.ico" />
            <meta key="charSet" charSet="utf-8" />
            <meta key="viewport" name="viewport" content="initial-scale=1.0, width=device-width" />
          </Head>
          <Title>心血管慢病数字化管理软件</Title>
          <AppLoading />
          <Loading />
          <CheckAuth Component={Component} pageProps={pageProps}>
            <LoginTipModal />
            <Component {...pageProps} />
          </CheckAuth>
        </RootStoreProvider>
      </div>
    </ConfigProvider>
  );
}

export default MyApp;
