/**
 *
 * - antd message 不要在这里导入
 *
 */
import type { Extra, Config, UploadProgress, InternalConfig } from 'qiniu-js/esm/upload';
// import type { CustomError } from 'qiniu-js/esm/errors';
import { QiniuError, QiniuNetworkError, QiniuRequestError, upload } from 'qiniu-js';
import { getToken } from './token';

export { configGetToken } from './token';

export type UploadFilePutExtra = Partial<Extra>;
export type UploadFileConfig = Partial<Config>;
export interface UploadFileCompleteData {
  key: string;
  url: string;
  urlprefix: string;
  hash: string;
  size: number;
}

export interface UploadFileParams {
  file: File;
  putExtra?: UploadFilePutExtra;
  config?: Partial<InternalConfig>;
  onProgress?: (uploadProgress: UploadProgress) => void;
  onSuccess?: (data: UploadFileCompleteData) => void;
  onError?: (error: QiniuError | QiniuRequestError | QiniuNetworkError) => void;
  cancel?: (cancel: () => void) => void;
}

/**
 * https://developer.qiniu.com/kodo/sdk/javascript
 */
export async function uploadFile({ file, putExtra, config, cancel, onProgress, onSuccess, onError }: UploadFileParams) {
  const suffix = getSuffix(file);
  const { token, key, url, urlprefix } = await getToken({ suffix });
  const observable = upload(file, key, token, putExtra, config);

  let promiseResolve: undefined | ((value: UploadFileCompleteData) => void);
  let promiseReject: undefined | ((reason?: any) => void);
  const promise = new Promise<UploadFileCompleteData>((resolve, reject) => {
    promiseResolve = resolve;
    promiseReject = reject;
  });

  const subscription = observable.subscribe({
    next: (uploadProgress) => {
      // 主要用来展示进度
      onProgress?.(uploadProgress);
    },
    error: (errResult) => {
      // 失败报错信息
      onError?.(errResult);
      promiseReject!(errResult);
    },
    complete: (result) => {
      // 接收成功后返回的信息
      const data = { ...result, key, url, urlprefix };
      onSuccess?.(data);
      promiseResolve!(data);
    },
  });

  cancel?.(() => {
    subscription.unsubscribe();
  });

  return promise;
}

export function getSuffix(file: File) {
  return file.name.substring(file.name.lastIndexOf('.')).substr(1);
}
