import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '@my/mobx-rootstore';
import { UIStore } from '@/stores/ui/UIStore';
import AppLoading from '@components/ui-pc/app-loading';

const MyAppLoading: FC = () => {
  const uiStore = useStore(UIStore);

  return <AppLoading visible={uiStore.isAppLoading} />;
};

export default observer(MyAppLoading);
