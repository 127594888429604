export interface ApiErrorData {
  errCode: number;
  errMsg: string;
}

/**
 * 错误码 (20221109)
 */
export enum ErrCode {
  /**
   * 登录已过期 30205
   */
  LoginExpired = 30205,
  /**
   * 异地登录 30206
   */
  AbnormalLogin = 30206,
  /**
   * 未授权 10003
   */
  Unauthorized = 10003,
  /**
   * 用户已被禁用 30105
   */
  UserForbidden = 30105,
  /**
   * 机构已冻结 30104
   */
  UserFreeze = 30104,
  /**
   * 机构已禁用 40114
   */
  OrgForbidden = 40114,
  /**
   * 跳绳上传失败 10015
   */
  RopeUploadErr = 10015,
  /**
   * 不允许此操作 10022
   */
  NoAllow = 10022,
  /** ota 版本更新相关 */
  /**
   * 版本号重复 41503
   */
  OTAVersionRepeat = 41503,
  /**
   * 版本号不能为空(41501),
   */
  OTAVersionEmpty = 41501,
  /**
   * OTA版本文件不能为空(41502)
   */
  OTAVersionFileEmpty = 41502,
  /**
   * OTA记录不存在(41504)
   */
  OTAVersionRecordEmpty = 41504,

  /**
   * 登录错误码 for teacher
   * */
  /** 账号不存在(30201) */
  AccountNotExist = 30201,
  /** 用户名或密码错误(30202) */
  UserNameOrPasswordError = 30202,
  /** 密码错误(30203) */
  PasswordError = 30203,
  /** 验证码不正确(10021) */
  LoginCodeError = 10021,
  /** 验证码无效(10020) */
  LoginCodeInvalid = 10020,
  /** 短信验证码错误(30208) */
  PhoneCodeError = 30208,

  /**
   * 未知
   */
  Unkonwn = 500,

  /** 网络异常 */
  NetworkRequestFailed = 501,
  /**
   * 未授权
   */
  Unauthorized_Old = 498,
  /**
   * 登录已过期
   */
  LoginExpired_Old = 494,
}

export const DefaultError: ApiErrorData = {
  errCode: ErrCode.Unkonwn,
  errMsg: '服务器繁忙',
};
