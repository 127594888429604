/** 自定义消息类型 */
export enum IMMessageType {
  /** 未测量提醒 */
  NoMeasure = 'no_measure',
  /** 数据异常提醒 */
  DataAbnormal = 'bp_exception',
  /** 会员临近过期提醒 */
  MemberExpire = 'about_to_expire',
  /** 会员过去提醒 */
  MemberExpired = 'expired',
  /** 健康周报 */
  HealthWeekly = 'health_report_weekly',
  /** 健康月报 */
  HealthMonthly = 'health_report_monthly',
  /** 科普文章 */
  PopularScienceArticle = 'edu_content',
  /** 健康问答 */
  HealthQa = 'question',
  /** 未绑定设备提醒 */
  NoBindDevice = 'no_binding',
  /** 未关注公众号提醒 */
  NoFollowPublic = 'follow_offi_account',
  /** 未登录 */
  notLogin = 'notLogin',
}

/** 消息类型 */

export enum MessageType {
  /**
   * Message type: text message
   */
  MSG_TEXT = 'TIMTextElem',

  /**
   * Message type: image message
   */
  MSG_IMAGE = 'TIMImageElem',

  /**
   * Message type: audio message
   */
  MSG_AUDIO = 'TIMSoundElem',

  /**
   * Message type: file message
   */
  MSG_FILE = 'TIMFileElem',

  /**
   * Message type: emoji message
   */
  MSG_FACE = 'TIMFaceElem',

  /**
   * Message type: video message
   */
  MSG_VIDEO = 'TIMVideoFileElem',

  /**
   * Message type: location message
   */
  MSG_LOCATION = 'TIMLocationElem',

  /**
   * Message type: group notification
   */
  MSG_GRP_TIP = 'TIMGroupTipElem',

  /**
   * Message type: group system message
   */
  MSG_GRP_SYS_NOTICE = 'TIMGroupSystemNoticeElem',

  /**
   * Message type: custom message
   */
  MSG_CUSTOM = 'TIMCustomElem',

  /**
   * Message type: combined message
   */
  MSG_MERGER = 'TIMRelayElem',

  /**
   * High priority of group message. Recommended message type: red packet and gift message
   */
  MSG_PRIORITY_HIGH = 'High',

  /**
   * Normal priority of group message. Recommended message type: common text message
   */
  MSG_PRIORITY_NORMAL = 'Normal',

  /**
   * Low priority of group message. Recommended message type: like message
   */
  MSG_PRIORITY_LOW = 'Low',

  /**
   * Lowest priority of group message. Recommended message type: least important message
   */
  MSG_PRIORITY_LOWEST = 'Lowest',
}
export interface Conversation {
  /** 会话 ID */
  conversationID: string;
  type: string;
  /** 未读计数 */
  unreadCount: number;
  /** 最后一条信息 */
  lastMessage: LastMessage;
  /** 群信息 */
  groupProfile: Group;
  /** 个人信息 */
  userProfile: UserProfile;
}

/** 会话最后一条信息 */
export interface LastMessage {
  /** 群会话最新消息的发送者的昵称 */
  nick: string;
  /** 群会话最新消息的发送者的群名片 */
  nameCard: string;
  /** 当前会话最新消息的时间戳，单位：秒 */
  lastTime: number;
  /** 当前会话的最新消息的 Sequence */
  lastSequence: number;
  /** 最新消息来源用户的 userID */
  fromAccount: string;
  /** 最新消息的内容，用于展示。可能值：文本消息内容、"[图片]"、"[语音]"、"[位置]"、"[表情]"、"[文件]"、"[自定义消息]"。
若该字段不满足您的需求，您可以使用 payload 来自定义渲染。 */
  messageForShow: string;
  type: string;
  payload: any;
}

/** Group */
export interface Group {
  /** 群组的唯一标识，群组 ID，App 内保证唯一 */
  groupID: string;
  /** 群名称 */
  name: string;
  /** 群头像 */
  avatar: string;
  /** 群组类型 */
  type: string;
  /** 群主ID */
  ownerID: string;
  /** 最后一条信息 */
  lastMessage: LastMessage;
}
export interface UserProfile {
  /** 用户账号 */
  userID: string;
  /** 昵称，长度不得超过500个字节 */
  nick: string;
  /** 性别 
@@IM.TYPES.GENDER_UNKNOWN（未设置性别）
@TIM.TYPES.GENDER_FEMALE（女）
@TIM.TYPES.GENDER_MALE（男） */
  gender: string;
  /** 生日 uint32 推荐用法：20000101 */
  birthday: number;
  /** 头像URL，长度不得超过500个字节 */
  avatar: string;
}
