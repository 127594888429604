import { ConversationManger } from './ConversationManger';
import { getMethod } from '../utils';
import { ChatSDK } from 'tim-js-sdk';

let conversationManger: undefined | ConversationManger;

export function initConversationManger(tim: ChatSDK) {
  conversationManger = new ConversationManger(tim);
}

function getConversationManger() {
  if (!conversationManger) throw new Error('请先调用 initConversationManger');
  return conversationManger;
}

export const getMessageList = getMethod(getConversationManger, 'getMessageList');
export const getConversationList = getMethod(getConversationManger, 'getConversationList');
export const setReadMessage = getMethod(getConversationManger, 'setReadMessage');
