import TIM, { ChatSDK, Message } from 'tim-js-sdk';

export interface MessageBaseProps {
  to: string;
  /** 群组类型 */
  conversationType?: TIM.TYPES;
  /** 消息优先级 */
  priority?: number;
}

type TextMessageProps = MessageBaseProps & { content: string };

type MediaMessageProps = MessageBaseProps & {
  /** 文件信息 */
  file: HTMLInputElement | File | Object;
  onProgress: (event: number) => void;
};

export class MessageManager {
  tim?: ChatSDK;
  constructor(tim: ChatSDK) {
    this.tim = tim;
  }

  /** 创建文本信息 */
  async createTextMessage({ to, conversationType, priority, content }: TextMessageProps) {
    return await this.tim?.createTextMessage({
      to,
      conversationType: conversationType ?? TIM.TYPES.CONV_GROUP,
      priority: priority ?? TIM.TYPES.MSG_PRIORITY_NORMAL,
      payload: {
        text: content,
      },
    });
  }

  /** 创建图片信息 */
  async createImageMessage({ to, conversationType, priority, file, onProgress }: MediaMessageProps) {
    return await this.tim?.createImageMessage({
      to,
      conversationType: conversationType ?? TIM.TYPES.CONV_GROUP,
      priority: priority ?? TIM.TYPES.MSG_PRIORITY_NORMAL,
      payload: {
        file,
      },
      onProgress: (event: number) => {
        onProgress(event);
      },
    });
  }
  /** 创建语音信息 */
  async createAudioMessage({
    to,
    conversationType,
    priority,
    file,
    onProgress,
  }: Omit<MediaMessageProps, 'onProgress'> & { onProgress?: (event: number) => void }) {
    return await this.tim?.createAudioMessage({
      to,
      conversationType: conversationType ?? TIM.TYPES.CONV_GROUP,
      priority: priority ?? TIM.TYPES.MSG_PRIORITY_NORMAL,
      payload: {
        file,
      },
      onProgress: (event: number) => {
        onProgress?.(event);
      },
    });
  }
  /** 创建视频信息 */
  async createVideoMessage({ to, conversationType, priority, file, onProgress }: MediaMessageProps) {
    return await this.tim?.createVideoMessage({
      to,
      conversationType: conversationType ?? TIM.TYPES.CONV_GROUP,
      priority: priority ?? TIM.TYPES.MSG_PRIORITY_NORMAL,
      payload: {
        file,
      },
      onProgress: (event: number) => {
        onProgress(event);
      },
    });
  }

  /** 发送信息 */
  async sendMessage(message: Message) {
    await this.tim?.sendMessage(message);
  }
}
