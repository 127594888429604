import { makeAutoObservable } from 'mobx';

import { RootStore } from '@my/mobx-rootstore';

export class UIStore {
  /**
   * 首次渲染完成
   */
  isMounted = false;
  /**
   * app 启动 Loading
   */
  isAppLoading = true;

  constructor(_rootStore: RootStore) {
    makeAutoObservable(this);
  }

  onRootStoreMounted() {
    this.isMounted = true;

    setTimeout(() => {
      this.setIsAppLoading(false);
    }, 500);
  }

  setIsAppLoading(loading: boolean) {
    this.isAppLoading = loading;
  }
}

export default UIStore;
