let _API_URL = '/api';

if (process.env.NODE_ENV === 'development') {
  const env = 'dev' as 'dev' | 'qa' | 'online';

  // if (env === 'dev') {
  //   _API_URL = 'http://platform.dev.suredoc.cn/api';
  // } else if (env === 'qa') {
  //   _API_URL = 'http://platform.qa.suredoc.cn/api';
  // }
  if (env === 'dev') {
    _API_URL = 'http://follow-up-admin-api.dev.suredoc.cn';
  } else if (env === 'qa') {
    _API_URL = 'http://follow-up-admin-api.qa.suredoc.cn';
  }
}

export const API_URL = _API_URL;
