import TIM, { ChatSDK } from 'tim-js-sdk';
import TIMUploadPlugin from 'tim-upload-plugin';

interface InitProps {
  SDKAppID: number;
  /** 日志级别 默认为0 日志量较多，接入时建议使用
   * @ 生产环境建议用1
   */
  logLevel: number;
}

export class CreateManger {
  tim?: ChatSDK;

  createIMSDK({ SDKAppID, logLevel }: InitProps) {
    // 创建 SDK 实例，TIM.create() 方法对于同一个 SDKAppID 只会返回同一份实例
    const options = {
      SDKAppID,
    };
    this.tim = TIM.create(options); // SDK 实例通常用 tim 表示

    this.tim.setLogLevel(logLevel ?? 1);
    // 注册腾讯云即时通信 IM 上传插件，即时通信 IM SDK 发送图片、语音、视频、文件等消息需要使用上传插件，将文件上传到腾讯云对象存储
    this.tim.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin });
    return this.tim;
  }

  /** 登录 */
  async IMLogin({ userID, userSig }: { userID: string; userSig: string }) {
    if (!this.tim) {
      throw new Error('请先初始化IMSDK');
    }
    const resp = await this.tim.login({ userID, userSig });
    if (!resp) {
      return;
    }
    if (resp.data.repeatLogin) {
      // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
      console.error(resp.data);
      return;
    }
  }

  /** 退出登录 */
  async IMLoginOut() {
    localStorage.removeItem('system');
    await this.tim?.logout();
  }

  /** 销毁 SDK 实例。SDK 会先 logout，然后断开 WebSocket 长连接，并释放资源 */
  async IMdestroy() {
    await this.tim?.destroy();
  }

  /** 监听IM事件 */
  onEvent({ eventType, handler }: { eventType: string; handler: Function }) {
    this.tim?.on(eventType, handler);
  }

  /** 取消监听IM事件 */
  offEvent({ eventType, handler }: { eventType: string; handler: Function }) {
    this.tim?.off(eventType, handler);
  }
}
