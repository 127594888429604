/**
 * 0-年，查询近一年的各月统计；1-月，查询近一月的各天统计；2-周，查询近一周的各天统计
 *
 */
export enum DateType {
  Year = 0,
  Mon = 1,
  Week = 2,
}
/**
 *
 *0：最近7日，1：最近4周，2：最近12个月
 */
export enum Recently {
  SevenDay = 0,
  FourWeek = 1,
  TwelveMon = 2,
}
