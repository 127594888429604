import { ChatSDK } from 'tim-js-sdk';

interface GET_MESSAGE_LIST_OPTIONS {
  /**
   * Conversation ID. Supported formats are as follows:
   * - C2C${userID} (one-to-one chat)
   * - GROUP${groupID} (group chat)
   * - @TIM#SYSTEM (system notification conversation)
   */
  conversationID: string;
  /**
   * Message ID, which is used to continue pulling messages by page. This parameter can be left unspecified the first time messages are pulled. Every time the API is called, this parameter is returned, and you need to specify it for the next pulling.
   */
  nextReqMessageID?: string;
}
export class ConversationManger {
  tim: ChatSDK;
  constructor(tim: ChatSDK) {
    this.tim = tim;
  }

  /** 获取消息列表 */
  async getMessageList({ conversationID, nextReqMessageID }: GET_MESSAGE_LIST_OPTIONS) {
    const resp = await this.tim.getMessageList({ conversationID, nextReqMessageID });
    return resp.data;
  }

  /** 获取会话列表 */
  async getConversationList() {
    const resp = await this.tim.getConversationList();
    return resp.data;
  }

  /** 将某个会话的未读消息设置为已读 */
  async setReadMessage({ conversationID }: { conversationID: string }) {
    await this.tim.setMessageRead({ conversationID });
  }
}
