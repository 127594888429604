import { ChatSDK } from 'tim-js-sdk';
import { getMethod } from '../utils';
import { MessageManager } from './MessageManager';

let messageManager: undefined | MessageManager;

export function initMessageManager(tim: ChatSDK) {
  messageManager = new MessageManager(tim);
}

function getMessageManager() {
  if (!messageManager) throw new Error('请先调用 initMessageManager');
  return messageManager;
}

export const sendMessage = getMethod(getMessageManager, 'sendMessage');
export const createTextMessage = getMethod(getMessageManager, 'createTextMessage');
export const createImageMessage = getMethod(getMessageManager, 'createImageMessage');
export const createVideoMessage = getMethod(getMessageManager, 'createVideoMessage');
export const createAudioMessage = getMethod(getMessageManager, 'createAudioMessage');
