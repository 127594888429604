import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { Paths, ResponseData } from '@my/swagger-typescript/types';
import {
  createCallApi as _createCallApi,
  RequestConfig as _RequestConfig,
  SuccessResponse as _SuccessResponse,
  ErrorResponse as _ErrorResponse,
} from '@my/call-api';
import { DefaultError } from '@services/call-api-shared/types';
import { ApiError } from './ApiError';

/**
 * callApi 参数
 */
type CustomRequestConfig = Omit<AxiosRequestConfig, 'url' | 'method' | 'params' | 'data'>;
export type RequestConfig<T extends Paths, U extends keyof T, M extends keyof T[U]> = _RequestConfig<T, U, M> &
  CustomRequestConfig;

/**
 * callApi 响应成功
 */
type CustomSuccessResponse = Omit<AxiosResponse, 'data'>;
export type SuccessResponse<D> = _SuccessResponse<D> & CustomSuccessResponse;

/**
 * callApi 响应成功
 */
export type ErrorResponse = _ErrorResponse & AxiosError;

/**
 * callApi 响应
 */
export type Response<D> = SuccessResponse<D> | ErrorResponse;

/**
 * 创建 callApi
 */
export function createCallApi<T extends Paths>(request: AxiosInstance) {
  return _createCallApi<T, CustomRequestConfig, CustomSuccessResponse, AxiosError>(
    async ({ url, method, query, data, ...rest }) => {
      const config: AxiosRequestConfig = {
        ...rest,
        url: url as string,
        method: method as any,
        params: query,
        data,
      };

      try {
        const resp = await request(config);
        return resp as unknown as SuccessResponse<ResponseData<T, any, any>>;
      } catch (error) {
        return formatError(error);
      }
    },
  );
}

/**
 * 格式化异常数据
 */
function formatError(error: any): ErrorResponse {
  let errCode: number | undefined;
  let errMsg: string | undefined;

  if (error instanceof ApiError) {
    errCode = error.errCode;
    errMsg = error.errMsg;
  } else if (axios.isAxiosError(error)) {
    const { response } = error;
    const data: any = response?.data;

    errCode = data?.errCode;
    errMsg = data?.errMsg;
  }

  return {
    ...error,
    error: {
      errCode: errCode ?? DefaultError.errCode,
      errMsg: errMsg ?? DefaultError.errMsg,
    },
  };
}
