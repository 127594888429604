import axios from 'axios';
import { configCallApi } from '@my/call-api/callApi';
import { createRequest, createCallApi } from '@services/call-api-axios';

import { API_URL } from '@/config';

import { rootStore } from '@/stores';
import { AuthStore } from '@/stores/modules/auth/AuthStore';
import { configGetToken } from '@services/upload-file';

import { Paths, ApiErrorData, ErrCode } from './types';

configCallApi({
  enabledMock: false,
});

export * from './types';

export const request = createRequest({
  baseURL: API_URL,
  auth() {
    const authStore = rootStore.getStore(AuthStore);

    if (!authStore.loginSession) return;

    return {
      accessToken: authStore.loginSession.accessToken,
    };
  },
});

request.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    if (axios.isAxiosError(error)) {
      const data = error.response?.data as Partial<ApiErrorData> | undefined;
      if (data?.errCode) {
        /**
         * 未授权、登录过期提示去登录
         */
        if ([ErrCode.Unauthorized, ErrCode.LoginExpired].includes(data.errCode)) {
          const authStore = rootStore.getStore(AuthStore);
          authStore.showLoginTipModal();
        }
      }
    }
    return Promise.reject(error);
  },
);

export const callApi = createCallApi<Paths>(request);
configGetToken((params) => callApi('/file/upload/token', 'get', { params }));
