import { Response } from '@my/call-api';

export enum MemberRole {
  /** 健管 */
  HEALTH_MANAGER = 0,
  /** 医生 */
  DOCTOR = 1,
  /** 患者 */
  PATIENT = 2,
}

/** 请求IM用户信息 */
export type CallIMUserInfoApi = (params: ImUserInfoParams) => Promise<Response<CallIMUserInfoApi.Response>>;
export namespace CallIMUserInfoApi {
  export type Response = UserSimpleDto[];
}

/** 请求IM群组的用户信息 */
export type CallIMGroupInfoApi = (params: ImUserInfoParams) => Promise<Response<CallIMGroupInfoApi.Response>>;
export namespace CallIMGroupInfoApi {
  export type Response = ImGroupMemberInfo[];
}

/** 请求医生关联的健管信息 */
export type CallHMInfoApi = () => Promise<Response<CallHMInfoApi.Response>>;
export namespace CallHMInfoApi {
  export type Response = HMInfo;
}
/** 请求医生关联的健管信息 */
export type CallDoctorSendRecord = (params: CallDoctorSendRecord.Params) => Promise<Response<unknown>>;
export namespace CallDoctorSendRecord {
  export type Params = SendParams;
}
export interface SendParams {
  /** 医生用户id */
  doctorUserId: string;
  /** 患者用户id */
  patientUserId: string;
  /** 发生消息的时间 */
  sendTime: string;
}
export interface HMInfo {
  userId: string;
  img: string;
  name: string;
}

export interface ImUserInfoParams {
  /** @description 群组id */
  groupIds?: string[];
  /** @description 用户id */
  userIds?: string[];
}

export interface UserSimpleDto {
  /** @description ID-userId */
  id?: string;
  /** @description 名称 */
  name?: string;
  /** @description 用户头像 */
  img?: string;
  /** Format: int32 */
  gender?: number;
  /** Format: int32 */
  age?: number;
  mobile?: string;
  /** Format: date-time */
  lastLoginTime?: string;
  /**
   * Format: int32
   * @description 角色 0:健管; 1:医生; 2:患者
   */
  role?: number;
  /** 疾病类型 */
  disease?: number;
}

export interface ImGroupMemberInfo {
  /** @description 群id */
  groupId?: string;
  memberInfoList?: ImUserRoleDto[];
}

export interface ImUserRoleDto {
  /** @description 用户id */
  userId?: string;
  /**
   * Format: int32
   * @description 角色 0-健管 1-医生 2-患者
   */
  role?: number;
  /** @description 患者的主账号userId */
  mainUserId?: string;
  /** @description 姓名 */
  name?: string;
  /** @description 头像 */
  img?: string;
  /** @description 年龄 */
  age?: number;
  /** @description 性别 */
  gender?: number;
  /** @description 疾病类型 */
  disease?: number;
}

export namespace CallCreateGroupApi {
  export type Response = string;
}
/** 请求创建群聊API */
export type CallCreateGroupApi = (params: DoctorPatientRelDto) => Promise<Response<CallCreateGroupApi.Response>>;

/** 请求医生关联的所有群组 */
export type CallGroupListApi = (params: ImGroupParams) => Promise<Response<CallGroupListApi.Response>>;
export namespace CallGroupListApi {
  export interface Response {
    list: ImGroupDto[];
    total: number;
  }
}

/** 请求医生聊天列表 */
export type CallDoctorChatListAPi = (params: HmDoctorPagingParams) => Promise<Response<CallDoctorChatListAPi.Response>>;
export namespace CallDoctorChatListAPi {
  export interface Response {
    list: HmChatRoomDto[];
    total: number;
  }
}

export type ImGroupDto = Required<{
  /** @description 群组id */
  id?: string;
  /** @description 医生用户id */
  doctorUserId?: string;
  /** @description 患者用户id */
  patientUserId?: string;
  /** @description 患者姓名 */
  patientName?: string;
  /** @description 患者头像 */
  patientUrl?: string;
}>;

export interface ImGroupParams {
  /**
   * Format: int32
   * @description 偏移量，从0开始，有此参数时忽略page
   */
  offset?: number;
  /**
   * Format: int32
   * @description 第几页，从1开始
   */
  page?: number;
  /**
   * Format: int32
   * @description 每页多少条记录
   */
  pageSize?: number;
  /** @description 排序字段名，多个以“,”隔开 */
  sort?: string;
  /** @description 排序方式,asc/desc，多个以“,”隔开，与sort一一匹配 */
  order?: string;
  /** @description 分页标记：每页最后一条记录的id */
  id?: string;
  /** @description 医生用户id */
  doctorUserId?: string;
  /** @description 群组名称 */
  name?: string;
}

export interface DoctorPatientRelDto {
  /** @description 患者用户id */
  patientUserId?: string;
  /** @description 医生用户id */
  doctorUserId?: string;
  /** @description 患者关联的主账号用户id */
  mainUserId?: string;
  /** @description 群名称 */
  name?: string;
  /** @description 群组头像-患者头像 */
  faceUrl?: string;
  /**
   * Format: int32
   * @description 是否提供健管
   */
  relationHealthManager?: number;
}

export interface HmDoctorPagingParams {
  /**
   * Format: int32
   * @description 偏移量，从0开始，有此参数时忽略page
   */
  offset?: number;
  /**
   * Format: int32
   * @description 第几页，从1开始
   */
  page?: number;
  /**
   * Format: int32
   * @description 每页多少条记录
   */
  pageSize?: number;
  /** @description 排序字段名，多个以“,”隔开 */
  sort?: string;
  /** @description 排序方式,asc/desc，多个以“,”隔开，与sort一一匹配 */
  order?: string;
  /** @description 分页标记：每页最后一条记录的id */
  id?: string;
  /** @description 健管userId */
  healthManagerUserId?: string;
  /** @description 姓名 */
  name?: string;
  /** @description 所属机构 */
  organ?: string;
}

export type HmChatRoomDto = Required<{
  /** @description 用户id */
  userId?: string;
  /** @description 姓名 */
  name?: string;
  /** @description 头像 */
  img?: string;
}>;

export function getRoleName(role?: MemberRole) {
  let roleText = {
    name: '',
    color: '',
  };
  switch (role) {
    case MemberRole.DOCTOR:
      roleText = {
        name: '医生',
        color: '#7AB4C0',
      };
      break;
    case MemberRole.HEALTH_MANAGER:
      roleText = {
        name: '健康管理师',
        color: '#7AB4C0',
      };
      break;
    case MemberRole.PATIENT:
      roleText = {
        name: '患者',
        color: '#E6B87F',
      };
  }
  return roleText;
}
