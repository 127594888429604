import { initCreateManger, creatIMSDK } from './createIM';
import { initMessageManager } from './message';
import { initConversationManger } from './conversation';
import { initGroupManager } from './group';

let isInit = false;

/** 创建IMSDK并初始化 */
export async function init({ SDKAppID, logLevel }: { SDKAppID: number; logLevel: number }) {
  if (isInit) return;
  isInit = true;
  initCreateManger();
  const tim = creatIMSDK({ SDKAppID, logLevel });
  initGroupManager(tim);
  initMessageManager(tim);
  initConversationManger(tim);
}
