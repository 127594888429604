import { CreateManger } from './CreateManger';
import { getMethod } from '../utils';

let createManger: undefined | CreateManger;

export function initCreateManger() {
  createManger = new CreateManger();
}

function getCreateManger() {
  if (!createManger) throw new Error('请先调用 initCreateManger');
  return createManger;
}

export const creatIMSDK = getMethod(getCreateManger, 'createIMSDK');
export const IMLogin = getMethod(getCreateManger, 'IMLogin');
export const IMLoginOut = getMethod(getCreateManger, 'IMLoginOut');
export const onEvent = getMethod(getCreateManger, 'onEvent');
export const offEvent = getMethod(getCreateManger, 'offEvent');
export const IMdestroy = getMethod(getCreateManger, 'IMdestroy');
