import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Button } from 'antd';

import { useStore } from '@my/mobx-rootstore';

import { AuthStore } from '@/stores/modules/auth/AuthStore';
import Image from '@components/next/image';
import img from '@/assets/imgs/nologin.png';

const LoginTipModal: FC = () => {
  const authStore = useStore(AuthStore);

  return (
    <Modal
      closable={false}
      maskClosable={false}
      cancelText={false}
      open={authStore.loginTipModal.visible}
      footer={null}
      zIndex={9999}
    >
      <div style={{ textAlign: 'center' }}>
        <div style={{ marginTop: '20px' }}>
          <Image alt="" src={img} width="100" height="100" />
        </div>
        <div style={{ marginBottom: '20px', marginTop: '20px' }}>你还没有登录，请先登录</div>
        <Button
          style={{ marginBottom: '20px' }}
          type="primary"
          loading={authStore.loginTipModal.loading}
          onClick={() => authStore.redirectToLogin()}
        >
          去登录
        </Button>
      </div>
    </Modal>
  );
};

export default observer(LoginTipModal);
