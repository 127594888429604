import { useEffect, useState } from 'react';

import { Spin } from 'antd';

import styles from './Loading.module.scss';
import classNames from 'classnames';

export const Loading = ({ loading }: { loading: boolean }) => {
  const [visible, setVisible] = useState(loading);

  useEffect(() => {
    let timeoutId: any;
    if (loading) {
      setVisible(true);
    } else {
      timeoutId = setTimeout(() => {
        setVisible(false);
      }, 400);
    }
    return () => timeoutId && clearTimeout(timeoutId);
  }, [loading]);

  return (
    <Spin
      wrapperClassName={classNames(styles.loading, { [styles.hide]: !visible })}
      spinning={loading}
      size="default"
      delay={200}
    >
      <div />
    </Spin>
  );
};

export default Loading;
