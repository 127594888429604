import { FC, PropsWithChildren, useEffect } from 'react';
import { AppProps } from 'next/app';
import { observer } from 'mobx-react-lite';

import { useStore } from '@my/mobx-rootstore';
import { AuthStore } from '@/stores/modules/auth/AuthStore';

const Auth: FC<PropsWithChildren<Pick<AppProps<any>, 'Component' | 'pageProps'>>> = ({
  children,
  Component,
  pageProps,
}) => {
  const authStore = useStore(AuthStore);
  useEffect(() => {
    if (pageProps.authRequired !== false) {
      authStore.checkAuth();
    }
  }, [Component, authStore, pageProps.authRequired]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default observer(Auth);
