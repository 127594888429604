import { FC, PropsWithChildren } from 'react';
import Head from 'next/head';

/**
 * 设置页面 title
 */
export const Title: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Head>
      <title key="title">{children}</title>
    </Head>
  );
};
export default Title;
