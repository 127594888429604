import { imageView2, ImageView2Options } from '@my/qiniu';
import { useMemo } from 'react';
import { isStaticImport, isStaticRequire, StaticImport } from './helper';

export function useQiniuSrc({
  src,
  width,
  height,
  imageView2Options,
  autoImageView2,
}: {
  src: string | StaticImport;
  height?: string | number;
  width?: string | number;
  imageView2Options?: ImageView2Options;
  autoImageView2?: boolean;
}) {
  return useMemo(() => {
    // 如果是对象，
    if (isStaticImport(src)) {
      const staticImageData = isStaticRequire(src) ? src.default : src;
      return {
        ...staticImageData,
        src: toImageView2Src({
          src: staticImageData.src,
          ...(width ?? height
            ? { width, height }
            : {
                width: staticImageData.width,
                height: staticImageData.height,
              }),
          imageView2Options: imageView2Options,
          autoImageView2,
        }),
      };
    }

    return toImageView2Src({
      src,
      width,
      height,
      imageView2Options: imageView2Options,
      autoImageView2,
    });
  }, [src, autoImageView2, imageView2Options, width, height]);
}

function toImageView2Src({
  src,
  width,
  height,
  imageView2Options,
  autoImageView2,
}: {
  src: string;
  height?: string | number;
  width?: string | number;
  imageView2Options?: ImageView2Options;
  autoImageView2?: boolean;
}) {
  if (imageView2Options) {
    return imageView2(src, imageView2Options);
  }

  if (autoImageView2 !== false) {
    const w = typeof width === 'number' ? width : undefined;
    const h = typeof height === 'number' ? height : undefined;
    if (w || h) {
      return imageView2(src, { mode: 2, w, h });
    }
  }

  return src;
}
