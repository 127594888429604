// 格式化数据时间

import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);

export interface timeFormatProps {
  /** 数据时间戳 */
  time: number | string;
  /**
   *  需要格化时间的符号 例如 2021年10月10日   [年,月,日] 默认为 [/,/]
   */
  formatSymbol?: string[];
  /** 是否隐藏时分秒显示  默认为显示 */
  isHideHour?: boolean;
  /** 今天的数据是否显示月份  默认不显示 */
  todayShowMonth?: boolean;
  /** 今天的数据是否隐藏小时， 默认为显示 */
  todayHideHour?: boolean;
  /** 是否需要显示时分秒  默认为显示 */
  hideHMS?: boolean;
}

export function timeFormat({
  time,
  isHideHour,
  todayShowMonth,
  todayHideHour,
  formatSymbol,
  hideHMS,
}: timeFormatProps) {
  const symbol = formatSymbol ?? ['/', '/'];
  /** 今年 */
  const thisYear = dayjs().startOf('y');
  /** 数据年 */
  const dataYear = dayjs(time).startOf('y');

  // 如果不是本年显示年份
  if (thisYear.diff(dataYear, 'y') >= 1) {
    return dayjs(time).format(`YYYY${symbol[0]}MM${symbol[1]}DD${symbol?.[2] ?? ''} ${!isHideHour ? 'HH:mm' : ''}`);
  }
  // 如果不是今天显示月份
  if (dayjs().startOf('d').diff(dayjs(time).startOf('d'), 'h') >= 24) {
    return dayjs(time).format(`MM${symbol[1]}DD${symbol[2] ?? ''} ${!isHideHour ? 'HH:mm' : ''}`);
  }

  /** 隐藏时分秒 */
  if (hideHMS) {
    return dayjs(time).format(
      // 今天数据是否显示月份
      `${todayShowMonth ? `MM${symbol[1]}DD${symbol[2] ?? ''} ` : ''} ${!todayHideHour ? 'HH:mm' : ''}`,
    );
  }

  // 如果是今天上午
  if (dayjs().diff(time, 'h', true) >= 1 && Number(dayjs(time).format('H')) < 12) {
    return `上午${dayjs(time).format('HH:mm')}`;
  }
  // 如果是今天下午
  if (dayjs().diff(time, 'h', true) >= 1 && Number(dayjs(time).format('H')) >= 12) {
    return `下午${dayjs(time).format('HH:mm')}`;
  }

  // 超过1分钟切小于1个小时
  if (dayjs().diff(time, 'm', true) >= 1) {
    return `${dayjs().diff(time, 'm')}分钟前`;
  }

  // 未超过一分钟
  // 超过1分钟切小于1个小时
  if (dayjs().diff(time, 'm', true) < 1) {
    if (dayjs().diff(time, 's') === 0) return '刚刚';
    return `${dayjs().diff(time, 's')}秒前`;
  }
}

export interface IMTimeFormatProps {
  time: number | string;
  // 是否显示小时和分钟
  isShowHAndM?: boolean;
}

export function IMTimeFormat({ time, isShowHAndM }: IMTimeFormatProps) {
  const IMTime = dayjs(time);
  const IMStartOfDay = IMTime.startOf('day');
  const diffDay = dayjs().startOf('day').diff(IMStartOfDay, 'd');
  // 一周之前 显示 YYYY/MM/DD HH:SS
  if (dayjs().startOf('isoWeek').diff(IMTime.startOf('isoWeek'), 'week', true) >= 1) {
    return isShowHAndM ? IMTime.format('YYYY/MM/DD HH:mm') : IMTime.format('YYYY/MM/DD');
  }
  // 后天及一周内展示为 星期X HH:mm
  if (diffDay >= 2 && diffDay < 7) {
    const weekDay = IMTime.isoWeekday();
    const weekDayText = '星期' + '日一二三四五六'.charAt(weekDay);
    return isShowHAndM ? `${weekDayText} ${IMTime.format('HH:mm')}` : weekDayText;
  }
  // 昨天的展示为 昨天 HH:mm
  if (diffDay >= 1 && diffDay < 2) {
    return isShowHAndM ? `昨天 ${IMTime.format('HH:mm')}` : '昨天';
  }
  // 今天的消息展示为  HH:mm
  return IMTime.format('HH:mm');
}
