import type { AxiosResponse, RawAxiosRequestHeaders } from 'axios';
import axios from 'axios';
import { nanoid } from 'nanoid';
import { DefaultError, ErrCode } from '@services/call-api-shared/types';
import { ApiError } from './ApiError';

declare module 'axios' {
  export interface AxiosRequestConfig {
    /**
     * 是否需要授权，默认为需要，除非传 false
     */
    authRequired?: boolean;
    /**
     * 输出日志
     */
    log?: boolean;
  }
}

interface AuthData {
  accessToken: string;
}

interface CreateOptions {
  baseURL: string;
  auth: () => AuthData | undefined | Promise<AuthData | undefined>;
}

export function createRequest({ baseURL, auth }: CreateOptions) {
  const instance = axios.create({
    baseURL,
  });
  /**
   * access token
   */
  instance.interceptors.request.use(async (config) => {
    /**
     * add requestId to headers
     */
    config.headers = {
      ...config.headers,
      requestId: nanoid(),
    } as any;

    /**
     * auth： accessToken
     */
    const { authRequired } = config;

    if (authRequired !== false) {
      let authData: AuthData | undefined;
      try {
        authData = await auth();
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('[Request Auth Error]', error);
        }
      }

      if (!authData) {
        const error = new ApiError({
          message: '未授权',
          code: 'UNAUTHORIZED',
          config,
          errCode: ErrCode.Unauthorized,
          errMsg: '未授权',
        });
        throw error;
      }

      config.headers = {
        ...config.headers,
        accessToken: authData.accessToken,
      } as any;
    }

    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      const data = response.data as Record<string, any> | undefined;

      if (data?.errCode !== undefined) {
        const errCode: number = data?.errCode ?? DefaultError.errCode;
        const code = String(errCode);
        const msg: string = data?.errMsg ?? DefaultError.errMsg;
        const error = new ApiError({
          message: msg,
          code,
          config: response.config,
          request: response.request,
          response,
          errCode,
          errMsg: msg,
        });
        logError(error);
        return Promise.reject(error);
      }

      if (response.config.log) {
        logSuccess(response);
      }

      return response;
    },
    (error) => {
      logError(error);
      return Promise.reject(error);
    },
  );

  return instance;
}

function logSuccess(response: AxiosResponse) {
  if (process.env.NODE_ENV === 'production') return;
  const headers = response.config.headers as RawAxiosRequestHeaders;
  console.info(`[Request Success]:
${generateLogContent({
  url: response.config.url,
  method: response.config.method,
  requestId: headers?.requestId as string,
  responseData: response?.data,
})}`);
}

function logError(error: any) {
  if (process.env.NODE_ENV === 'production') return;
  try {
    if (axios.isAxiosError(error)) {
      const requestConfig = error.response?.config || error.config;
      const responseData = error.response?.data;
      if (requestConfig) {
        const headers = requestConfig.headers as RawAxiosRequestHeaders;
        console.warn(`[Request Error]:
${generateLogContent({
  url: requestConfig.url,
  method: requestConfig.method,
  requestId: headers?.requestId as string,
  responseData,
})}`);
      } else {
        console.warn('[Request Error]:', error.message);
      }
    }
  } catch (error) {
    console.error('[Request logError Error]', error);
  }
}

function generateLogContent({
  url,
  method,
  requestId,
  responseData,
}: {
  url?: string;
  method?: string;
  requestId?: string;
  responseData?: any;
}) {
  return `  url:        ${url}
  method:     ${method}
  requestId:  ${requestId}
  response:   ${limitString(dataToString(responseData))}`;
}

function dataToString(data: any) {
  if (typeof data === 'string') return data;
  try {
    const jsonStr = JSON.stringify(data);
    return `${jsonStr}`;
  } catch (error: any) {
    return `data to string error: ${error.msg || error.message || 'unkonwn error'}`;
  }
}

function limitString(str: string) {
  if (str.length > 300) return `${str.substring(0, 300)}...`;
  return str;
}
