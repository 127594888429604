export interface MockOptions<T> {
  delay?: number;
  data?: (() => T | Promise<T>) | T;
}

export async function mock<T>(options: MockOptions<T>) {
  const delay = options.delay ?? 500;

  await new Promise((resolve) => {
    setTimeout(resolve, delay);
  });

  return generateData(options.data);
}

function generateData<T>(data: MockOptions<T>['data']) {
  if (typeof data === 'function') return (data as any)();

  return data;
}
