import { observer } from 'mobx-react-lite';

import { useStore } from '@my/mobx-rootstore';
import { Loading } from '@components/ui-pc/loading';
import LoadingStore from '@/stores/ui/loading/LoadingStore';

const MyLoading = observer(() => {
  const loadingStore = useStore(LoadingStore);

  return <Loading loading={loadingStore.isLoading} />;
});

export default MyLoading;
