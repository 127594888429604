import { ApiResponseData, callApi } from '@/apis';
import TIM, { Conversation, Message } from 'tim-js-sdk';
import { init as initIM, IMLogin, onEvent, setReadMessage } from '@shared/common/helper/IMSDK';
import { makeAutoObservable, runInAction } from 'mobx';
import { rootStore } from '@/stores';
import { AuthStore } from '@/stores/modules/auth/AuthStore';
import { ConversationStore } from '@medical/components-pc/services/IM/conversationBox';

export type ConversationFromServer = ApiResponseData<'/im/conversations', 'post'>['list'][number] & {
  unreadCount?: number;
};

export class IMBaseStore {
  /** 会话列表 */
  conversationList?: Conversation[] = undefined;
  /** 最新收到的消息 */
  newMessage?: Message[];
  /** 是否已经初始化 */
  isInit = false;
  /** 未读对话 */
  unReadNum = 0;
  /** 后端返回的会话列表 */
  conversationListFromServer: ConversationFromServer[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  get AuthStore() {
    return rootStore.getStore(AuthStore);
  }

  get ConversationStore() {
    return rootStore.getStore(ConversationStore);
  }

  /** 登录IM */
  async loginIM() {
    const userId = this.AuthStore.loginSession?.userId;
    if (!userId) {
      throw new Error('请先登录');
    }

    const SDKAppID = await this.getSDKAppId();
    initIM({ SDKAppID: SDKAppID!, logLevel: 1 });
    const userSig = await this.getUserSig(userId);
    // 监听初始化
    this.initOnEvent();
    await this.getConversationList();
    // 使用主账号登录
    await IMLogin({ userID: userId, userSig: userSig! });
  }

  /** 初始化监听事件 */
  initOnEvent() {
    // 只执行一次监听
    if (this.isInit) {
      return;
    }
    // 监听新消息
    onEvent({
      eventType: TIM.EVENT.MESSAGE_RECEIVED,
      handler: (res: { data: Message[] | undefined }) => {
        // console.log('IM新消息', res.data);
        runInAction(() => {
          this.newMessage = res.data;
          const conversationID = this.ConversationStore.singleChat
            ? `C2C${this.ConversationStore.conversationId}`
            : `GROUP${this.ConversationStore.conversationId}`;
          const messageList = this.newMessage?.filter((message) => {
            return message.conversationID === conversationID;
          });
          if (messageList?.length) {
            setReadMessage({ conversationID });
          }
          messageList?.forEach((item: any) => {
            this.ConversationStore.messageListTable.list.push({
              ...item,
              // avatar: this.ConversationStore?.groupProfile.avatar || defaultImg.src,
              // time: item.time * 1000,
            });
          });
          // 如果新消息不存在会话列表中，就更新会话列表
          const isExist = this.conversationListFromServer?.find((item) => {
            return item.conversatonID === conversationID;
          });
          if (!isExist) {
            this.getConversationList();
          }
          this.ConversationStore.newMessage = messageList;
        });
      },
    });
    // 监听会话列表变更
    onEvent({
      eventType: TIM.EVENT.CONVERSATION_LIST_UPDATED,
      handler: (res: { data: Conversation[] | undefined }) => {
        runInAction(() => {
          this.conversationList = res.data;
          // const unReadNumList = res.data?.map((item) => {
          //   return [TIM.TYPES.CONV_GROUP, TIM.TYPES.CONV_C2C].includes(item.type) ? item.unreadCount : 0;
          // });
          // this.unReadNum = unReadNumList.reduce((pre, current) => {
          //   return pre + current;
          // });
          // 给会话列表添加未读数
          this.conversationList?.forEach((item) => {
            this.conversationListFromServer.forEach((serverItem) => {
              if (item.conversationID === serverItem.conversatonID) {
                serverItem.unreadCount = item.unreadCount;
                if (serverItem.lastMessage !== item.lastMessage.messageForShow) {
                  serverItem.lastMessage = item.lastMessage.messageForShow;
                  serverItem.lastMessageTime = (item.lastMessage.lastTime * 1000) as any;
                }
              }
            });
          });
          // 统计总未读消息数
          const unReadNums = this.conversationListFromServer.map((item) => {
            return item.unreadCount ?? 0;
          });
          this.unReadNum = unReadNums.reduce((pre, current) => {
            return pre + current;
          });
          this.saveDoctorUnRead({
            doctorUserId: this.AuthStore.loginSession?.userId ?? '',
            unreadMsgNums: this.unReadNum,
          });
        });
      },
    });
    this.isInit = true;
  }
  /** 获取后端存储的会话列表 */
  async getConversationList(type?: number) {
    const data = {
      pageSize: 100,
      type: type ?? 1, // 在医生端 这个参数不会被使用
    };
    const resp = await callApi('/im/conversations', 'post', {
      data,
    });
    if (resp.error) {
      console.error('im/conversations:获取会话列表失败');
      return;
    }
    runInAction(() => {
      this.conversationListFromServer = resp.data.list;
    });
    console.log('后端会话列表', resp.data.list);
  }
  /** 获取sdkappid */
  async getSDKAppId() {
    const resp = await callApi('/im/sdk-app-id', 'get');
    if (resp.error) {
      console.error('获取sdkappid失败');
      return;
    }
    return resp.data;
  }

  /** 获取userSig */
  async getUserSig(userId: string) {
    const resp = await callApi('/im/user-sig/{userId}', 'get', {
      urlParams: {
        userId,
      },
    });
    if (resp.error) {
      console.error('获取userSig失败');
      return;
    }
    return resp.data;
  }
  /** 保存医生未读消息数 */
  async saveDoctorUnRead({ doctorUserId, unreadMsgNums }: { doctorUserId: string; unreadMsgNums: number }) {
    const resp = await callApi('/doctor/save/unread-im-nums', 'post', {
      data: {
        doctorUserId,
        unreadMsgNums,
      },
    });
    if (resp.error) {
      console.error(`存医生未读消息报错:${resp.error.errMsg}`);
      return;
    }
  }
}
