import { AxiosInstance } from 'axios';
import { ErrCode } from '@services/call-api-shared/types';

/** 未登录: 拦截后续请求 */
export function unAuthPlugin(instance: AxiosInstance) {
  instance.interceptors.request.use(async (config) => {
    return config;
  });
  let isCancel: boolean;

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (isCancel) {
        return new Promise(() => {});
      }
      if (
        [
          ErrCode.Unauthorized,
          ErrCode.LoginExpired,
          ErrCode.AbnormalLogin,
          ErrCode.Unauthorized_Old,
          ErrCode.LoginExpired_Old,
        ].includes(error?.errCode)
      ) {
        isCancel = true;
      }

      return Promise.reject(error);
    },
  );

  return instance;
}
