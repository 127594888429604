import { GetUploadFileToken } from './type';

const config: { getUploadFileToken?: GetUploadFileToken } = {};

export function configGetToken(getUploadFileToken: GetUploadFileToken) {
  config.getUploadFileToken = getUploadFileToken;
}

export async function getToken({ suffix }: { suffix: string }) {
  if (process.env.NODE_ENV !== 'production') {
    if (!config.getUploadFileToken) {
      throw new Error('请先执行 "@services/upload-file" configGetToken');
    }
  }
  const resp = await config.getUploadFileToken!({ suffix });

  if (resp.error) {
    throw new Error(resp.error.errMsg);
  }

  return resp.data;
}

export default getToken;
