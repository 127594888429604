/**
 * 角色
 *
 * 院长 - DEAN
 *
 */
export enum RoleCode {
  /** 院长 - DEAN */
  Dean = 'DEAN',
  /** 医生 - DOCTOR */
  Doctor = 'DOCTOR',
  /** 观察者 - OBSERVER */
  Observer = 'OBSERVER',
  /** 健康管理师 - HEALTH_MANAGER */
  HEALTH_MANAGER = 'HEALTH_MANAGER',
}
/**
 * 性别
 *
 * 男 Male = 1
 *
 * 女 Female = 2
 *
 * 未知 Unkonw = 3
 */
export enum Gender {
  /** 男 - 1 */
  Male = 1,
  /** 女 - 2 */
  Female = 2,
  /** 未知 - 0 */
  Unknown = 0,
}
