import type { Paths, ResponseData } from '@my/swagger-typescript/types';
import queryString from 'query-string';

import type { RequestConfig, SuccessResponse, Response, CustomRequest, CustomRequestParams, CallApi } from './types';
import { mock as runMock } from './mock';
import { replaceUrlParams } from './utils';

export interface GlobalConfig {
  /**
   * 禁用 mock
   */
  enabledMock?: boolean;
}

/** 全局设置 */
const globalConfig: GlobalConfig = {};

/**
 * 修改全局配置
 */
export function configCallApi(newConfig: Partial<GlobalConfig>) {
  Object.assign(globalConfig, newConfig);
}

/**
 * 创建 callApi
 */
export function createCallApi<T extends Paths, ExtraC = {}, ExtraD = {}, ExtraE = {}>(
  request: CustomRequest<T, ExtraC, ExtraD, ExtraE>,
): CallApi<T, ExtraC, ExtraD, ExtraE> {
  /**
   * 调用 api
   * @param url
   * @param method
   * @param params
   */
  return async <U extends keyof T, M extends keyof T[U]>(
    url: U,
    method: M,
    config?: ExtraC & RequestConfig<T, U, M>,
  ): Promise<Response<ResponseData<T, U, M>, ExtraD, ExtraE>> => {
    const { urlParams, params, data, mock, ...rest } = config || {};
    if (process.env.NODE_ENV === 'development' && globalConfig.enabledMock && mock) {
      const mockData = await runMock(mock);

      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const resp = { data: mockData } as ExtraD & SuccessResponse<ResponseData<T, U, M>>;
      return resp;
    }

    const requestUrl = urlParams ? replaceUrlParams(url as string, urlParams as any) : url;

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const requestParams = {
      ...rest,
      url: requestUrl as U,
      method,
      query: params as Record<string, any>,
      data,
    } as ExtraC & CustomRequestParams<T, U, M>;
    return request({
      paramsSerializer: {
        serialize: (params: Record<string, any>) => {
          return queryString.stringify(params, { arrayFormat: 'none' });
        },
      },
      ...requestParams,
    });
  };
}
