import { ChatSDK } from 'tim-js-sdk';

export class GroupManager {
  tim?: ChatSDK;
  constructor(tim: ChatSDK) {
    this.tim = tim;
  }

  /** 获取群组资料 */
  async getGroupProfile({ groupID }: { groupID: string }) {
    const groupProfile = await this.tim?.getGroupProfile({ groupID: groupID });
    return groupProfile;
  }
}
