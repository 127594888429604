export * from '@services/call-api-shared/types';

// configCallApi
export { configCallApi } from '@my/call-api';
export type { GlobalConfig } from '@my/call-api';

// mock
export { mock } from '@my/call-api';
export type { MockOptions } from '@my/call-api';

// ApiErrorCode
export * from '@services/call-api-shared/ErrorCode';

export * from './createCallApi';
export * from './request';
export * from './ApiError';
export * from './plugins';
