import type { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import axios from 'axios';

const { AxiosError } = axios;

export class ApiError extends AxiosError {
  static isApiError(error: any): error is ApiError {
    return error instanceof ApiError;
  }

  errCode: number;
  errMsg: string;

  constructor({
    message,
    code,
    config,
    request,
    response,
    errCode,
    errMsg,
  }: {
    message: string;
    code: string;
    config: InternalAxiosRequestConfig;
    request?: any;
    response?: AxiosResponse;
    errCode: number;
    errMsg: string;
  }) {
    super(message, code, config, request, response);

    this.errCode = errCode;
    this.errMsg = errMsg;
  }
}
