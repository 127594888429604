import React, { useEffect, useMemo } from 'react';
import Image from '@components/next/image';

import dayjs from 'dayjs';
import classNames from 'classnames';
import styles from './index.module.scss';

import { IMMessageType } from '@shared/common/helper/IMAction';

import error_img from '../assets/error_img.png';
import pressure from '../assets/pressure.png';
import answer from '../assets/answer.png';
import health from '../assets/health.png';
import exclusive from '../assets/exclusive.png';
import customization from '../assets/customization.png';
import abnormal from '../assets/abnormal.png';
import week from '../assets/week.png';
import month from '../assets/month.png';
import week_icon from '../assets/week_icon.png';
import month_icon from '../assets/month_icon.png';
import answer_icon from '../assets/answer_icon.png';
import qr_bg from '../assets/qr_bg.png';

export interface IMMessageTypeProps {
  type: IMMessageType;
  data: any;
  /** 是否深色模式 ，背景是白色的需要用到次背景 */
  isDark?: boolean;
  backgroudColor?: string;
  /** 是否只能查看 */
  isOnlyView?: boolean;
  /** 问答选择的问题 */
  onSelectAnswer?: ({ data, type }: { data: { id: string; answer: number[] }; type: number }) => void;
  /** 去绑定设备 */
  bindDeviceClick?: () => void;
  /** 去查看月报 */
  onChangeReport?: ({
    reportId,
    begin,
    end,
    mode,
  }: {
    reportId: string;
    begin: string;
    end?: string;
    mode: string;
  }) => void;
  /** 去查看文章 */
  onChangeArticle?: (id: string) => void;
}

interface AnswerDataProps {
  /** 解析 */
  explain: string;
  /** 题目id */
  id: string;
  /** 问答类型 1：单选 2 多选 */
  type: string;
  /** 选项数组 */
  options: string[];
  /** 正确选项 */
  rightIndex: number[];
  /** 状态 */
  state: number;
  /** 问题 */
  title: string;
  /** 用户已选答案 */
  userIndex?: number[];
}

export const IMMessageTypeBox: React.FC<IMMessageTypeProps> = ({
  type,
  data,
  isDark,
  isOnlyView,
  onSelectAnswer,
  bindDeviceClick,
  onChangeReport,
  onChangeArticle,
}) => {
  const typeInfo = useMemo(() => {
    return type;
  }, [type]);
  const dataObj = useMemo(() => {
    if (!data) {
      return {};
    }
    return data;
  }, [data]);
  return (
    <div className={classNames(styles['message_box'], isDark && styles['message_box_dark'])}>
      {typeInfo === IMMessageType.DataAbnormal && <ErrorMessage value={dataObj.noticeText} />}
      {[IMMessageType.MemberExpire, IMMessageType.MemberExpired].includes(typeInfo) && (
        <MemberRemind contentText={dataObj.noticeText} />
      )}
      {typeInfo === IMMessageType.PopularScienceArticle && (
        <Article
          title={dataObj.name}
          contentText={dataObj.profile}
          onChangeArticle={() => onChangeArticle?.(dataObj.id)}
        />
      )}
      {typeInfo === IMMessageType.HealthQa && (
        <Answer type={dataObj.type} data={dataObj} onSelectAnswer={onSelectAnswer} isOnlyView={isOnlyView} />
      )}
      {[IMMessageType.HealthWeekly, IMMessageType.HealthMonthly].includes(typeInfo) && (
        <Report
          type={typeInfo}
          begin={dataObj.begin}
          end={dataObj?.end}
          onChangeReport={() => {
            onChangeReport?.({
              reportId: dataObj.reportId,
              begin: dataObj.begin,
              end: dataObj?.end,
              mode: typeInfo === IMMessageType.HealthWeekly ? 'week' : 'month',
            });
          }}
        />
      )}
      {typeInfo === IMMessageType.NoBindDevice && <BindDevice isDark bindDeviceClick={bindDeviceClick} />}
      {typeInfo === IMMessageType.NoFollowPublic && <Attention />}
    </div>
  );
};
/** 答题 */
const Answer: React.FC<{
  type: number;
  data: AnswerDataProps;
  isOnlyView?: boolean;
  onSelectAnswer?: ({ data, type }: { data: { id: string; answer: number[] }; type: number }) => void;
}> = ({ type, data, isOnlyView, onSelectAnswer }) => {
  // 已选择
  const [select, setSelect] = React.useState<number[]>([]);
  // 是否显示确定
  const [showConfirm, setShowConfirm] = React.useState(false);
  // 是否显示解析
  const [showAnalysis, setShowAnalysis] = React.useState(false);
  // 是否不可选择
  const [disabled, setDisabled] = React.useState(false);

  const radio = data.options;
  const checked = data.options;
  const isSingle = type === 1;

  /** 单选答案 */
  const answerTxt = () => {
    return data.rightIndex.map((v) => getLetter(v)).join('');
  };
  const radioClick = (index: number) => {
    if (isOnlyView) return;
    if (!!data.userIndex && data.userIndex.length) return;
    if (select.length || disabled) return;
    setSelect([index]);
    setShowAnalysis(true);
    setDisabled(true);
    onSelectAnswer?.({ data: { id: data.id, answer: [index] }, type });
  };

  const checkedClick = (index: number) => {
    if (isOnlyView) return;
    if (!!data.userIndex && data.userIndex.length) return;
    if (disabled) return;
    // 如果存在就是删除
    if (select.includes(index)) {
      const newList = select.filter((item) => item !== index);
      setSelect(newList);
      setShowConfirm(!!newList.length);
      return;
    }
    // 如果不存在就是添加
    setSelect([...select, index]);
    setShowConfirm(true);
  };
  const checkedConfirm = () => {
    setShowConfirm(false);
    setShowAnalysis(true);
    setDisabled(true);
    onSelectAnswer?.({ data: { id: data.id, answer: select }, type });
  };
  useEffect(() => {
    if (data.userIndex) {
      setSelect(data.userIndex);
      setShowAnalysis(true);
    }
  }, [data.userIndex]);
  const RadioItem = (
    <div>
      {radio.map((v, i) => (
        <div key={v} className={styles['answer_item']} onClick={() => radioClick(i)}>
          <div className={classNames(styles['answer_item_radio'], { [styles['answer_selected']]: select.includes(i) })}>
            {getLetter(i)}
          </div>
          <div className={styles['warp_row2']}>{v}</div>
        </div>
      ))}
    </div>
  );
  const CheckedItem = (
    <div>
      {checked.map((v, i) => (
        <div key={v} className={styles['answer_item']} onClick={() => checkedClick(i)}>
          <div
            className={classNames(styles['answer_item_radio'], styles['answer_item_checked'], {
              [styles['answer_selected']]: select.includes(i),
            })}
          >
            {getLetter(i)}
          </div>
          <div className={styles['warp_row2']}>{v}</div>
        </div>
      ))}
    </div>
  );
  return (
    <div style={{ width: 400 }}>
      <div className={styles['row']}>
        <Image src={answer_icon} width={20} height={20} imageView2={{ mode: 1 }} />
        <div className={styles['article_title']}>健康问答</div>
      </div>
      <div style={{ marginTop: 6, marginBottom: 6, display: 'inline-block' }}>
        <span className={styles['answer_tag']}>{isSingle ? '单选' : '多选'}</span>
        <span>{data.title}</span>
      </div>
      {isSingle ? RadioItem : CheckedItem}
      {!isSingle && showConfirm && (
        <div className={styles['answer_btn']} onClick={() => checkedConfirm()}>
          确定
        </div>
      )}
      {showAnalysis && (
        <div className={styles['answer_item_analysis']}>
          <div
            className={classNames(styles['row'], styles['analysis_before'])}
            style={{ justifyContent: 'space-between' }}
          >
            <div className={styles['row']}>
              <div>答案：</div>
              <div style={{ color: '#007D96' }}>{answerTxt()}</div>
            </div>
            <div className={styles['row']} style={{ paddingRight: 5 }}>
              <div>您选择：</div>
              <div style={{ color: '#EF7A64' }}>{select.map((v) => getLetter(v)).join('')}</div>
            </div>
          </div>
          <div className={styles['analysis_before']} style={{ display: 'inline-block', marginTop: 20 }}>
            <span>解析：</span>
            <span className={styles['analysis_before_content']}>{data.explain}</span>
          </div>
        </div>
      )}
    </div>
  );
};
/** 周月报 */
const Report: React.FC<{ type: string; begin: string; end?: string; onChangeReport?: () => void }> = ({
  type,
  begin,
  end,
  onChangeReport,
}) => {
  const isWeek = type === IMMessageType.HealthWeekly;
  const dateTxt = isWeek
    ? `${dayjs(begin).format('MM.DD')}-${dayjs(end).format('MM.DD')}`
    : `${dayjs(begin).format('MM月')}`;
  return (
    <div onClick={() => onChangeReport?.()}>
      <div className={styles['row']}>
        <Image src={isWeek ? week_icon : month_icon} width={20} height={20} imageView2={{ mode: 1 }} />
        <div className={styles['article_title']}>健康报告</div>
      </div>
      <div style={{ marginTop: 6, marginBottom: 6 }}>
        您的{dateTxt}健康{isWeek ? '周报' : '月报'}
      </div>
      <Image src={isWeek ? week : month} width={214} height={168} imageView2={{ mode: 1 }} />
    </div>
  );
};
/** 提示去绑定设备 */
const BindDevice: React.FC<{ contentText?: string; isDark?: boolean; bindDeviceClick?: () => void }> = (props) => {
  const { contentText, isDark, bindDeviceClick } = props;
  const Item = ({ img, title, content }: { img: string | any; title: string; content: string }) => {
    return (
      <div className={styles['row_center']}>
        <Image src={img} width={36} height={36} imageView2={{ mode: 1 }} />
        <div className={styles['device_item_txt']}>
          <div>{title}</div>
          <div>{content}</div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <div>
        {contentText ??
          'HI~我是健康管理师小乐，绑定设备才可激活高血压专病管理服务哦。你还可以进入「乐家庭」添加家人，关注家人健康'}
      </div>
      <div className={styles['bind_device']} style={{ background: isDark ? '#FFF' : '#f3f3f3' }}>
        <div className={styles['device_list']}>
          {Item({ img: pressure, title: '居家血压', content: '安心监测' })}
          {Item({ img: abnormal, title: '紧急异常', content: '实时通知' })}
          {Item({ img: answer, title: '在线解答', content: '咨询交流' })}
          {Item({ img: health, title: '健康指导', content: '及提醒' })}
          {Item({ img: exclusive, title: '专属数字', content: '健康档案' })}
          {Item({ img: customization, title: '定制专属', content: '健康计划' })}
        </div>
        <div className={styles['bottom_txt']} style={{ padding: 7 }} onClick={() => bindDeviceClick?.()}>
          绑定并激活
        </div>
      </div>
    </div>
  );
};
/** 关注公众号 */
const Attention: React.FC = () => {
  return (
    <div>
      <div className={styles['attention_top']}>
        <div>关注乐心医疗服务号</div>
        <div>让消息提醒更快一步</div>
      </div>
      <div className={styles['attention_qrBox']}>
        <div>图片</div>
        <Image src={qr_bg} className={styles['attention_qrBox-bg']} />
      </div>
      <div className={styles['attention_bottom']}>
        <div>点击并长按二维码图片可直接</div>
        <div>关注公众号，或者下载二维码</div>
        <div>图片扫码进行关注</div>
      </div>
    </div>
  );
};
/** 科普文章 */
const Article: React.FC<{ title: string; contentText: string; onChangeArticle?: () => void }> = ({
  title,
  contentText,
  onChangeArticle,
}) => {
  return (
    <div onClick={() => onChangeArticle?.()}>
      <div className={styles['row']}>
        <Image src={error_img} width={20} height={20} imageView2={{ mode: 1 }} />
        <div className={styles['article_title']}>科普文章</div>
      </div>
      <div className={styles['article_content']}>{title}</div>
      <div className={styles['article_content_tip']}>{contentText}</div>
    </div>
  );
};
/** 会员提醒 */
const MemberRemind: React.FC<{ contentText: string; onChangeMemberRemind?: () => void }> = ({
  contentText,
  onChangeMemberRemind,
}) => {
  return (
    <div>
      <div>{contentText}</div>
      <BottomBtn text="前往续费" onClick={() => onChangeMemberRemind?.()} />
    </div>
  );
};
/** 异常信息 */
const ErrorMessage: React.FC<{ value: string }> = ({ value }) => {
  return (
    <div>
      <div className={styles['row']}>
        <Image src={error_img} width={20} height={20} imageView2={{ mode: 1 }} />
        <div className={styles['error_title']}>[血压异常]</div>
      </div>
      <div className={styles['error_txt']}>{value}</div>
      {/* <BottomBtn text="查看血压异常的危害" onClick={() => {}} /> */}
    </div>
  );
};

const BottomBtn: React.FC<{ text: string; onClick: () => void }> = ({ text, onClick }) => {
  return (
    <div className={styles['bottom_txt']} onClick={() => onClick()}>
      {text}
    </div>
  );
};

/** 传入数组下标 返回 A、B、C...26位 */
function getLetter(index: number) {
  const letter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  return letter[index];
}
