import { RootStore } from '@my/mobx-rootstore';

import { ReactElement } from 'react';

import { CallIMUserInfoApi, CallIMGroupInfoApi, CallDoctorSendRecord } from '../type';

export interface ConversationBoxConfig {
  // <Icon type="icon-a-guanbi1" />
  closeIcon: ReactElement;
  currentUserImg?: string;
  CallIMUserInfoApi: CallIMUserInfoApi;
  CallIMGroupInfoApi: CallIMGroupInfoApi;
  /** /im/im-send-record/{doctorUserId}/{patientUserId}/{sendTime} 医生发送消息记录 */
  callDoctorSendRecord?: CallDoctorSendRecord;
}

export type GetConfig = (rootStore: RootStore) => ConversationBoxConfig;

let internalGetConfig: GetConfig | undefined;

export function setConfig(getConfig: GetConfig) {
  internalGetConfig = getConfig;
}

export function getConfig(rootStore: RootStore) {
  if (!internalGetConfig) throw new Error(`ConversationBox: 请先调用 setConfig`);

  return internalGetConfig(rootStore);
}
