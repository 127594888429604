/**
 * 将 url 中的 {xx} 替换为 params 对应的值
 * @param url
 * @param params
 */
export function replaceUrlParams(url: string, params: Record<string, string>) {
  return Object.keys(params).reduce((_url, key) => {
    return _url.replace(new RegExp(`{${key}}`, 'g'), params[key]);
  }, url);
}
