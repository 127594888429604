export interface ImageView2Options {
  mode: 0 | 1 | 2 | 3 | 4 | 5;
  w?: number;
  h?: number;
  format?: string;
  interlace?: boolean;
  quality?: number;
  ignoreError?: boolean;
  colors?: 2 | 4 | 8 | 16 | 32 | 64 | 128 | 256;
}

/**
 * https://developer.qiniu.com/dora/1279/basic-processing-images-imageview2
 */
export function imageView2(
  src: string,
  { mode, w, h, format, interlace, quality, ignoreError, colors }: ImageView2Options,
) {
  if (typeof src !== 'string') return src;
  if (src.startsWith('data:')) return src;
  let newSrc = `${src}?imageView2/${mode}`;

  if (w) newSrc += `/w/${w}`;
  if (h) newSrc += `/h/${h}`;
  if (format) newSrc += `/format/${format}`;
  if (interlace) newSrc += '/interlace/1';
  if (quality) newSrc += `/q/${quality}`;
  if (ignoreError) newSrc += '/ignore-error/1';
  if (colors) newSrc += `/colors/${colors}`;

  return newSrc;
}
