import { HTMLAttributes, useState, ReactNode, useEffect, useRef, forwardRef, useImperativeHandle, FC } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import Image from '@components/next/image';
import { IMTimeFormat } from '@shared/common/helper/timeFormat';
import { Button, Input, Image as AntdImage } from 'antd';
import { MessageType } from '@shared/common/helper/IMAction';
import { useStore, useUnregister } from '@my/mobx-rootstore';
import { ConversationStore } from './index.store';
import BScroll from 'better-scroll';
import { getRoleName } from '../type';
import { IMMessageTypeBox } from '../IMMessageType';

import styles from './index.module.scss';

import imageIcon from '../assets/icon_image.png';
import videoIcon from '../assets/icon_video.png';
import play from '../assets/play.gif';
import voice from '../assets/voice.png';

import { useCounter, usePrevious } from 'ahooks';

const { TextArea } = Input;

export interface ConversationBoxProps {
  /** 会话名称 */
  conversationName?: string;
  /** 会话id */
  conversationId: string;
  /** 是否自定义头部 */
  isCutom?: boolean;
  /** 头部title */
  header?: ReactNode;
  /** 是否单聊 */
  singleChat?: boolean;
  /** 用户id */
  patientUserId?: string;
  /** 医生id */
  doctorUserId?: string;
  /** header 头部 右上角内容 */
  headerRight?: ReactNode;
}
export type ConversationBoxComponent = FC<ConversationBoxProps>;

/** 会话框 */
export const ConversationBox = observer(
  ({
    conversationName,
    conversationId,
    isCutom,
    header,
    singleChat,
    patientUserId,
    doctorUserId,
    headerRight,
  }: ConversationBoxProps) => {
    const conversationStore = useStore(ConversationStore);
    useEffect(() => {
      conversationStore.init({ conversationId, singleChat, doctorUserId, patientUserId });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversationId]);
    useUnregister(conversationStore);

    const [isPreImage, setPreImage] = useState(false);
    const [isPreVideo, setPreVideo] = useState(false);

    const listRef = useRef<ListRef>(null);
    const inputRef = useRef<any>(null);

    const [scrollBottomMark, { inc: scrollBottom }] = useCounter();
    useEffect(() => {}, [headerRight]);
    useEffect(() => {
      setTimeout(() => {
        listRef.current?.scrollBottom();
        inputRef.current?.focus({
          cursor: 'end',
        });
      }, 300);
    }, [
      scrollBottomMark,
      conversationStore.newMessage,
      conversationId,
      conversationStore.messageListTable.list.length,
      conversationStore.groupMemberInfo,
    ]);

    return (
      <div className={styles.conversationBox}>
        {isCutom ? (
          header
        ) : (
          <div className={styles.ConversationName}>
            <div>{conversationStore.ConversationName ?? conversationName}</div>
            {headerRight}
          </div>
        )}
        <List
          key={conversationId}
          className={classNames(styles.conversationBoxContent, 'wrapper')}
          ref={listRef}
          recordList={conversationStore.recordList}
        >
          <div className={classNames(styles.messageList, 'content')}>
            {conversationStore.recordList.length >= 15 && (
              <div className={styles.moreBtn}>
                {conversationStore.nextReqMessage?.isCompleted ? (
                  <span className={styles.noRecord}>没有更多了</span>
                ) : (
                  <span
                    className={styles.hisRecordBrn}
                    onClick={() => {
                      conversationStore.getRecordList({});
                    }}
                  >
                    查看更多消息
                  </span>
                )}
              </div>
            )}

            {conversationStore.recordUIDataList?.map((item) => {
              return (
                <div className={classNames(styles.messageItem, item.flow === 'out' && styles.messageOut)} key={item.id}>
                  <div className={styles.avatar}>
                    <Image imageView2={{ mode: 1, w: 48, h: 48 }} src={item.avatar} width={48} height={48} />
                  </div>
                  <div className={styles.messageItem_right}>
                    {item.flow === 'in' && !conversationStore.singleChat && (
                      <div className={styles.nameBox}>
                        <div className={styles.memberName}>{item.name}</div>
                        <div style={{ color: getRoleName(item.role).color }}>{getRoleName(item.role).name}</div>
                      </div>
                    )}
                    <div className={styles.record}>
                      {item.type === MessageType.MSG_TEXT && (
                        <div className={styles.recordBox}>{item.payload.text}</div>
                      )}
                      {item.type === MessageType.MSG_CUSTOM && (
                        <IMMessageTypeBox
                          type={item.payload.desc}
                          data={item.payload.data}
                          isOnlyView
                          // onSelectAnswer={({ data, type }) => {
                          //   console.log('选择了答案', data, type);
                          //   conversationStore.setAnswered({ id: data.id, userIndex: data.answer, i });
                          // }}
                          // onChangeReport={({ reportId, mode }) => {
                          //   conversationStore.goToReportDetail(reportId, mode);
                          // }}
                          // onChangeArticle={(id) => {
                          //   conversationStore.goToArticleDetail(id);
                          // }}
                        />
                      )}
                      {item.type === MessageType.MSG_AUDIO && (
                        <div
                          className={classNames(styles.voice_box, item.flow === 'out' ? styles.voice_right : '')}
                          onClick={() => {
                            conversationStore.playAudio(item.payload.url);
                          }}
                        >
                          <Image
                            src={conversationStore.playingUrl === item.payload.url ? play : voice}
                            width={12}
                            height={16}
                            className={item.flow === 'out' ? styles.voice_right_img : ''}
                          />
                          <div style={{ marginLeft: 5, marginRight: 5 }}>{`${item.payload.second}"`}</div>
                        </div>
                      )}
                      {item.type === MessageType.MSG_IMAGE && (
                        <div className={styles.recordImageBox}>
                          <Image
                            className={styles.recordImage}
                            imageView2={{ mode: 1 }}
                            src={item.imageUrl}
                            width={item.imageWidth >= 150 ? 150 : item.imageWidth}
                            height={
                              // 高度同比例缩放
                              item.imageWidth >= 150 ? (150 / item.imageWidth) * item.imageHeight : item.imageHeight
                            }
                            onClick={() => {
                              conversationStore.preViewMdeia(item.imageUrl);
                              setPreImage(true);
                            }}
                          />
                        </div>
                      )}
                      {item.type === MessageType.MSG_VIDEO && (
                        <div className={styles.recordImageBox}>
                          <div
                            className={styles.videoImage}
                            onClick={() => {
                              setPreVideo(true);
                              conversationStore.preViewMdeia(item.payload.videoUrl);
                            }}
                          >
                            <Image
                              src={item.payload.thumbUrl ?? item.payload.thumbUrl}
                              imageView2={{ mode: 1 }}
                              width={120}
                              height={68}
                            />
                            {item.payload.videoSecond > 0 && (
                              <div className={styles.videoTime}>
                                {Math.floor(item.payload.videoSecond / 60) >= 10
                                  ? Math.floor(item.payload.videoSecond / 60)
                                  : `0${Math.floor(item.payload.videoSecond / 60)}`}
                                :
                                {Math.floor(item.payload.videoSecond % 60) >= 10
                                  ? Math.floor(item.payload.videoSecond % 60)
                                  : `0${Math.floor(item.payload.videoSecond % 60)}`}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      <div className={styles.recordTime}>{IMTimeFormat({ time: item.time, isShowHAndM: true })}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </List>
        <div className={styles.inputBox}>
          <div className={styles.conversationAPP}>
            {/* <div className={styles.APPItem}>
          <Image src={faceIcon} width={28} height={28} />
        </div> */}
            <div className={styles.APPItem}>
              <input
                className={styles.APPItemInput}
                type="file"
                onChange={(e) => {
                  scrollBottom();
                  conversationStore.getFiles({ enent: e, type: 'image' });
                }}
                ref={(e) => conversationStore.inputElement({ Element: e, type: 'image' })}
                style={{ visibility: 'hidden' }}
                accept=".jpg, .jpeg, .png, .gif"
                multiple
              />
              <Image
                src={imageIcon}
                width={28}
                height={28}
                onClick={() => {
                  conversationStore.chooseMedia({ type: 'image' });
                }}
              />
            </div>
            <div className={styles.APPItem}>
              <input
                className={styles.APPItemInput}
                type="file"
                onChange={(e) => {
                  console.log('视频', e);
                  scrollBottom();
                  conversationStore.getFiles({ enent: e, type: 'video' });
                }}
                ref={(e) => conversationStore.inputElement({ Element: e, type: 'video' })}
                style={{ visibility: 'hidden' }}
                accept=".mp4, .rmvb, .AVI , mov"
                multiple
              />
              <Image
                src={videoIcon}
                width={28}
                height={28}
                onClick={() => {
                  conversationStore.chooseMedia({ type: 'video' });
                }}
              />
            </div>
            {/* <div className={styles.APPItem}>
          <Image src={fileIcon} width={28} height={28} />
        </div> */}
          </div>
          {/* kim update autoSize 原：minRows: 1, maxRows: 2 */}
          <div className={styles.conversationInput}>
            <TextArea
              autoSize={{ minRows: 4, maxRows: 4 }}
              bordered={false}
              onChange={(e) => {
                conversationStore.getInputValue(e.currentTarget.value);
              }}
              onPressEnter={async (e) => {
                e.preventDefault();
                await conversationStore.sendTextMessage();
                scrollBottom();
              }}
              value={conversationStore.inputValue}
              ref={inputRef}
            />
          </div>
          <div className={styles.sendBtn}>
            <Button
              type="primary"
              onClick={async () => {
                await conversationStore.sendTextMessage();
                scrollBottom();
              }}
            >
              发送
            </Button>
          </div>
        </div>
        {conversationStore.preViewMediaUrl && (
          <AntdImage
            preview={{
              visible: isPreImage,
              src: conversationStore.preViewMediaUrl,
              onVisibleChange: (value) => {
                setPreImage(value);
                conversationStore.clearPreViewMdeia();
              },
            }}
          />
        )}

        {isPreVideo && (
          <PreVideoModel
            setClose={(close) => {
              setPreVideo(close);
            }}
          />
        )}
      </div>
    );
  },
);

const PreVideoModel = observer(({ setClose }: { setClose: (close: boolean) => void }) => {
  const conversationStore = useStore(ConversationStore);
  const {
    config: { closeIcon },
  } = conversationStore;
  return (
    <div className={styles.preVideoModel}>
      <div className={styles.closeModel}>
        <span
          onClick={() => {
            setClose(false);
            conversationStore.clearPreViewMdeia();
          }}
        >
          {/* <Icon type="icon-a-guanbi1" /> */}
          {closeIcon}
        </span>
      </div>
      <iframe width={740} height={740} src={conversationStore.preViewMediaUrl}></iframe>
    </div>
  );
});

type ListProps = { children: ReactNode; recordList?: ConversationStore['recordList'] } & HTMLAttributes<HTMLDivElement>;
interface ListRef {
  scrollBottom: () => void;
}

const List = forwardRef<ListRef, ListProps>(({ recordList, children, ...props }, ref) => {
  const divRef = useRef<HTMLDivElement>(null);

  const bsRef = useRef<BScroll>();

  const prevRecordList = usePrevious(recordList);

  useEffect(() => {
    if ((recordList?.length ?? 0) > 0 && !prevRecordList?.length) {
      const bs = bsRef.current!;
      bs?.scrollTo(0, bs.maxScrollY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordList]);

  useEffect(() => {
    if (!divRef.current) return;
    const bs = (bsRef.current = new BScroll(divRef.current, {
      scrollY: true,
      bounceTime: 300,
      mouseWheel: {
        speed: 20,
        invert: false,
        easeTime: 300,
      },
      observeDOM: true,
      preventDefault: false,
      disableTouch: false,
      scrollbar: true,
    }));
    return () => {
      bs.destroy();
    };
  }, []);

  useImperativeHandle(ref, () => {
    return {
      scrollBottom: () => {
        const bs = bsRef.current;
        if (!bs) return;
        bs.scrollTo(0, bs.maxScrollY);
      },
    };
  });

  return (
    <div ref={divRef} {...props}>
      {children}
    </div>
  );
});
