import type { FC } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import styles from './AppLoading.module.scss';

interface AppLoadingProps {
  visible: boolean;
}

const indicator = <LoadingOutlined suppressHydrationWarning style={{ fontSize: 36 }} spin />;

export const AppLoading: FC<AppLoadingProps> = ({ visible }) => {
  return (
    <div className={classNames(styles['app-loading'], { [styles['hide']]: !visible })}>
      <Spin indicator={indicator} />
    </div>
  );
};

export default AppLoading;
