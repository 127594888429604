export enum ApiErrorCode {
  /** ---------------- 系统级响应代码 --------------- */
  /** 10001=系统错误 */
  E_10001 = 10001,
  /** 10002=签名错误 */
  E_10002 = 10002,
  /** 10003=未授权 */
  E_10003 = 10003,
  /** 10004=无操作权限 */
  E_10004 = 10004,
  /** 10010=重复请求 */
  E_10010 = 10010,
  /** 10011=请求超时 */
  E_10011 = 10011,
  /** 10012=当前服务器繁忙 */
  E_10012 = 10012,
  /** 10013=第三方服务器异常 */
  E_10013 = 10013,
  /** 10014=服务器异常 */
  E_10014 = 10014,
  /** 10015=数据异常 */
  E_10015 = 10015,
  /** 10016=正在处理请求 */
  E_10016 = 10016,
  /** 10017=无效的登录端 */
  E_10017 = 10017,
  /** 10018=错误请求 */
  E_10018 = 10018,
  /** 10019=无访问权限 */
  E_10019 = 10019,
  /** 10020=验证码已失效 */
  E_10020 = 10020,
  /** 10021=验证码不正确 */
  E_10021 = 10021,
  /** 10101=找不到路径 */
  E_10101 = 10101,
  /** 10102=禁止访问 */
  E_10102 = 10102,
  /** 10103=错误JSON */
  E_10103 = 10103,
  /** 10104=不支持的媒体类型 */
  E_10104 = 10104,

  /** ---------------- API参数级响应代码 ---------------- */
  /** 20101=参数无效 */
  E_20101 = 20101,
  /** 20102=参数过长 */
  E_20102 = 20102,
  /** 20103=参数为空 */
  E_20103 = 20103,
  /** 20104=参数非中英文 */
  E_20104 = 20104,
  /** 20105=参数非数字 */
  E_20105 = 20105,
  /** 20201=手机号重复 */
  E_20201 = 20201,
  /** 20202=手机号格式错误 */
  E_20202 = 20202,
  /** 20203=证件号码重复 */
  E_20203 = 20203,
  /** 20204=身份证号格式错误 */
  E_20204 = 20204,
  /** 20205=邮箱重复 */
  E_20205 = 20205,
  /** 20206=邮箱格式错误 */
  E_20206 = 20206,
  /** 20207=验证码不正确 */
  E_20207 = 20207,
  /** 20208=配置值缺省 */
  E_20208 = 20208,
  /** 20209=手机号或邮箱不存在 */
  E_20209 = 20209,
  /** 20210=无效的设置项 */
  E_20210 = 20210,
  /** 20301=错误JSON */
  E_20301 = 20301,
  /** 20302=JSON解析失败 */
  E_20302 = 20302,
  /** 20303=参数解析错误 */
  E_20303 = 20303,
  /** 20401=时间无效 */
  E_20401 = 20401,
  /** 20402=错误的时间格式 */
  E_20402 = 20402,
  /** 20403=年龄无效 */
  E_20403 = 20403,

  /** ---------------- API用户级响应代码 ---------------- */
  /** 30101=用户不存在 */
  E_30101 = 30101,
  /** 30102=用户名已存在 */
  E_30102 = 30102,
  /** 30103=角色不存在 */
  E_30103 = 30103,
  /** 30104=用户已被冻结 */
  E_30104 = 30104,
  /** 30105=用户已被禁用 */
  E_30105 = 30105,
  /** 30106=无操作权限 */
  E_30106 = 30106,
  /** 30107=用户账号不存在 */
  E_30107 = 30107,
  /** 30108=用户已存在 */
  E_30108 = 30108,
  /** 30109=账号已存在类似角色 */
  E_30109 = 30109,
  /** 30201=账号不存在 */
  E_30201 = 30201,
  /** 30202=用户名或密码错误 */
  E_30202 = 30202,
  /** 30203=密码错误 */
  E_30203 = 30203,
  /** 30204=登录失败 */
  E_30204 = 30204,
  /** 30205=登录已过期 */
  E_30205 = 30205,
  /** 30206=用户已在其他地方登录 */
  E_30206 = 30206,
  /** 30207=短信发送失败 */
  E_30207 = 30207,
  /** 30208=验证码不正确请重新输入 */
  E_30208 = 30208,
  /** 30209=账号已冻结 */
  E_30209 = 30209,
  /** 30210=登录信息无效 */
  E_30210 = 30210,
  /** 30211=账号未关联任何角色 */
  E_30211 = 30211,
  /** 30212=账号下已关联该角色 */
  E_30212 = 30212,
  /** 30213=账号已关联手机号 */
  E_30213 = 30213,
  /** 30214=账号已关联邮箱 */
  E_30214 = 30214,
  /** 30215=创建失败该用户账号已经有对应的手机号 */
  E_30215 = 30215,
  /** 30216=创建失败该用户账号已经有对应的邮箱 */
  E_30216 = 30216,
  /** 30217=手机号已关联账号 */
  E_30217 = 30217,
  /** 30218=邮箱已关联账号 */
  E_30218 = 30218,
  /** 30219=错误的验证码类型 */
  E_30219 = 30219,
  /** 30220=请忽重复发送 */
  E_30220 = 30220,
  /** 30221=您的账号已锁定请30分钟后再登录 */
  E_30221 = 30221,
  /** 30222=短信/邮箱验证码已失效 */
  E_30222 = 30222,
  /** 30223=该账号无可登录角色 */
  E_30223 = 30223,
  /** 30301=授权用户不存在 */
  E_30301 = 30301,
  /** 30302=非微信用户 */
  E_30302 = 30302,
  /** 30303=微信用户不存在 */
  E_30303 = 30303,
  /** 30304=已授权其他账号 */
  E_30304 = 30304,
  /** 30305=未绑定系统用户 */
  E_30305 = 30305,
  /** 30306=更新绑定用户失败 */
  E_30306 = 30306,
  /** 30307=不存在绑定关系 */
  E_30307 = 30307,

  /** ---------------- API业务级区间代号 ---------------- */
  /** 401=机构 */
  E_401 = 401,
  /** 402=导入文件 */
  E_402 = 402,
  /** 403=设备管理 */
  E_403 = 403,
  /** 404=教师 */
  E_404 = 404,
  /** 405=班级 */
  E_405 = 405,
  /** 406=学生 */
  E_406 = 406,
  /** 407=家庭作业 */
  E_407 = 407,
  /** 408=体育课 */
  E_408 = 408,
  /** 409=赛事活动 */
  E_409 = 409,
  /** 410=考试 */
  E_410 = 410,
  /** 411=体测 */
  E_411 = 411,
  /** 412=视频 */
  E_412 = 412,
  /** 413=小程序 */
  E_413 = 413,
  /** 414=家庭成员 */
  E_414 = 414,
  /** 420=队员 */
  E_420 = 420,
  /** 421=教练 */
  E_421 = 421,
  /** 422=运动队 */
  E_422 = 422,
  /** 423=运动项目 */
  E_423 = 423,
  /**  424=训练课 */
  E_424 = 424,

  /** ---------------- 训练课响应代码 ---------------- */
  /** 42401=训练课时不存在 */
  E_42401 = 42401,
  /** 42402=无心率数据 */
  E_42402 = 42402,
  /** 42403=开课队伍下无队员 */
  E_42403 = 42403,
  /** 42404=队员有变动请重新选择队员 */
  E_42404 = 42404,
  /** 42405=设备组下不存在心率设备 */
  E_42405 = 42405,
  /** 42406=设备数量不足 */
  E_42406 = 42406,
  /** 42407=参加训练课队员未绑定自用手环 */
  E_42407 = 42407,
  /** 42408=训练课结束失败 */
  E_42408 = 42408,
  /** 42409=参数开始时间不能大于结束时间 */
  E_42409 = 42409,
  /** 42410=当前开课教练未带领队伍 */
  E_42410 = 42410,
  /** 42411=教练只能开自己所带队伍的训练课 */
  E_42411 = 42411,
  /** 42412=当前队伍有未结束的训练课 */
  E_42412 = 42412,
  /** 42413=开课未选择队员 */
  E_42413 = 42413,
  /** 42414=换绑设备队员未设置年龄 */
  E_42414 = 42414,
  /** 42415=换绑设备队员未设置体重 */
  E_42415 = 42415,
  /** 42416=参与训练课队员未设置年龄或者体重 */
  E_42416 = 42416,

  /** ---------------- API训练课/体育课响应代码 ---------------- */
  /** 40801=开课人非当前登陆用户 */
  E_40801 = 40801,
  /** 40802=设备组号不能为空 */
  E_40802 = 40802,
  /** 40803=该设备正在使用中 */
  E_40803 = 40803,
  /** 40804=开课教师不存在 */
  E_40804 = 40804,
  /** 40805=开课班级不存在 */
  E_40805 = 40805,
  /** 40806=班级下不存在学生 */
  E_40806 = 40806,
  /** 40807=班级有课堂未结束 */
  E_40807 = 40807,
  /** 40808=设备组下不存在心率设备 */
  E_40808 = 40808,
  /** 40809=心率设备数不足 */
  E_40809 = 40809,
  /** 40810=当前用户未匹配设备 */
  E_40810 = 40810,
  /** 40811=设备mac不存在 */
  E_40811 = 40811,
  /** 40812=开课分组数无效 */
  E_40812 = 40812,
  /** 40813=最多允许分组数52 */
  E_40813 = 40813,
  /** 40814=每组数量至少为2 */
  E_40814 = 40814,
  /** 40815=开课班级下不存在学生 */
  E_40815 = 40815,
  /** 40816=设备组不存在 */
  E_40816 = 40816,
  /** 40817=设备组内不存在设备 */
  E_40817 = 40817,
  /** 40818=跳绳设备数不足 */
  E_40818 = 40818,
  /** 40819=课时不存在 */
  E_40819 = 40819,
  /** 40820=课时相关数据不存在 */
  E_40820 = 40820,
  /** 40821=课时下不存在学生数据 */
  E_40821 = 40821,
  /** 40822=分组对抗参数不能为空 */
  E_40822 = 40822,
  /** 40823=分组学生数据不能为空 */
  E_40823 = 40823,
  /** 40824=体育课未开启跳绳轮次 */
  E_40824 = 40824,
  /** 40825=不存在该跳绳使用场景 */
  E_40825 = 40825,
  /** 40826=mac数据不存在 */
  E_40826 = 40826,

  /** ---------------- API机构响应代码 ---------------- */
  /** 40101=机构不存在 */
  E_40101 = 40101,
  /** 40102=级别无效 */
  E_40102 = 40102,
  /** 40103=已存在省级教体局且一个省只能创建一个省级教体局 */
  E_40103 = 40103,
  /** 40104=学校level错误 */
  E_40104 = 40104,
  /** 40105=机构名称已存在 */
  E_40105 = 40105,
  /** 40108=不能重复创建相同地区级别的学校 */
  E_40108 = 40108,
  /** 40109=机构的parentId不存在 */
  E_40109 = 40109,
  /** 40110=当前手机号已经是管理员 */
  E_40110 = 40110,
  /** 40111=机构关联旧帐户不存在 */
  E_40111 = 40111,
  /** 40112=当前区域暂无学校可选 */
  E_40112 = 40112,
  /** 40113=学校不在当前区域 */
  E_40113 = 40113,
  /** 40114=机构已禁用 */
  E_40114 = 40114,

  /** ---------------- API考试/体测响应代码 ---------------- */
  /* 41000=该地区无评分标准 */
  E_41000 = 41000,
  /* 41001=考试记录不存在 */
  E_41001 = 41001,
  /* 41002=考试记录下学生不存在 */
  E_41002 = 41002,
  /* 41003=考试项目不存在 */
  E_41003 = 41003,
  /* 41004=进行中的考试才能编辑学生成绩 */
  E_41004 = 41004,
  /* 41005=不存在可分组的学生 */
  E_41005 = 41005,
  /* 41006=当前用户正添加考试计划中 */
  E_41006 = 41006,
  /* 41007=分组数据不在在 */
  E_41007 = 41007,
  /* 41008=组内学生已存在成绩 */
  E_41008 = 41008,
  /* 41009=小组正在考试中 */
  E_41009 = 41009,
  /* 41110=分组不存在 */
  E_41110 = 41110,
  /* 41101=体测记录不存在 */
  E_41101 = 41101,
  /* 41102=学段已经存在体测任务 */
  E_41102 = 41102,
  /* 41103=所选学段暂无班级 */
  E_41103 = 41103,
  /* 41104=当前存在体测任务 */
  E_41104 = 41104,
  /* 41105=当前体测状态不能操作 */
  E_41105 = 41105,
  /* 41106=体测项目不存在 */
  E_41106 = 41106,
  /* 41111=不支持的分组方式 */
  E_41111 = 41111,
  /* 41112=该小组已超过上限人数 */
  E_41112 = 41112,
  /* 41113=项目不支持分组 */
  E_41113 = 41113,
  /* 41114=分组不存在 */
  E_41114 = 41114,
  /* 41115=组内存在学生 */
  E_41115 = 41115,
  /* 41116=项目不存在未分组学生 */
  E_41116 = 41116,
  /* 41117=该学生已分组 */
  E_41117 = 41117,
  /* 41118=该学生并非本场测试学生 */
  E_41118 = 41118,
  /* 41119=该学生无需参考当前项目 */
  E_41119 = 41119,
  /* 41120=当前登陆用户已开启跳绳录入学生 */
  E_41120 = 41120,
  /* 41121=当前登陆用户未开启跳绳录入学生 */
  E_41121 = 41121,
  /* 41122=该学生已经被其他用户检录 */
  E_41122 = 41122,
  /* 41123=已选设备箱号不能更换 */
  E_41123 = 41123,
  /* 41124=当前用户正添加体测计划中 */
  E_41124 = 41124,
  /* 41125=组内学生已存在成绩 */
  E_41125 = 41125,
  /* 41126=小组正在考试中 */
  E_41126 = 41126,

  /** ---------------- 跳绳轮次响应代码 ---------------- */
  /* 41701=轮次不存在 */
  E_41701 = 41701,
  /* 41702=轮次已结束 */
  E_41702 = 41702,
  /* 41703=当前不存在正在进行的轮次 */
  E_41703 = 41703,
  /* 41704=上一轮次未结束不能开启 */
  E_41704 = 41704,

  /** ---------------- API教练响应代码 ---------------- */
  /** 42101=不存在该教练 */
  E_42101 = 42101,
  /** 42102=不存在该教练角色 */
  E_42102 = 42102,
  /** 42103=不存在该教练账户 */
  E_42103 = 42103,
  /** 42104=该账户下已有教练角色 */
  E_42104 = 42104,
  /** 42105=该教练正在上课无法删除 */
  E_42105 = 42105,
  /** 42106=该队伍没有教练带领 */
  E_42106 = 42106,
  /** 42107=该教练为队伍唯一教练无法删除 */
  E_42107 = 42107,

  /** ---------------- API学员响应代码 ---------------- */
  /** 42001=不存在该队员 */
  E_42001 = 42001,
  /** 42002=不存在该队员角色 */
  E_42002 = 42002,
  /** 42003=该队员不存在账户 */
  E_42003 = 42003,
  /** 42004=该账户下已存在队员角色 */
  E_42004 = 42004,
  /** 42005=该手机号已存在队员角色 */
  E_42005 = 42005,
  /** 42006=该邮箱已存在队员角色 */
  E_42006 = 42006,
  /** 42007=该账户下已关联该角色 */
  E_42007 = 42007,
  /** 42008=原队伍已删除可选择换队切换其他队伍 */
  E_42008 = 42008,

  /** ---------------- API运动项目响应代码 ---------------- */
  /** 423001=该运动项目不存在 */
  E_423001 = 423001,
  /** 423002=该运动项目名称已存在 */
  E_423002 = 423002,

  /** ---------------- API训练队响应代码 ---------------- */
  /** 42201=队伍名称已重复 */
  E_42201 = 42201,
  /** 42202=该队伍不存在 */
  E_42202 = 42202,
  /** 42203=没有教练带领该队伍 */
  E_42203 = 42203,
  /** 42204=队伍已开课无法删除 */
  E_42204 = 42204,

  /** ---------------- API设备响应代码 ---------------- */
  /** 40301=设备MAC已存在 */
  E_40301 = 40301,
  /** 40302=设备SN已存在 */
  E_40302 = 40302,
  /** 40303=设备MAC或SN已存在 */
  E_40303 = 40303,
  /** 40304=设备MAC不存在 */
  E_40304 = 40304,
  /** 40305=设备SN不存在 */
  E_40305 = 40305,
  /** 40306=设备不存在 */
  E_40306 = 40306,
  /** 40307=设备已被绑定 */
  E_40307 = 40307,
  /** 40308=用户已绑定相同类型设备 */
  E_40308 = 40308,
  /** 40309=绑定设备关系不存在 */
  E_40309 = 40309,
  /** 403010=设备组不存在 */
  E_403010 = 403010,
  /** 403011=设备正在使用中 */
  E_403011 = 403011,
  /** 403012=设备组未绑定机构 */
  E_403012 = 403012,
  /** 403013=设备组已绑定机构 */
  E_403013 = 403013,
  /** 40314=相同设备组中的编号重复 */
  E_40314 = 40314,
  /** 40315=心率带没有设备箱号 */
  E_40315 = 40315,
  /** 403016=公用跳绳不存在 */
  E_403016 = 403016,
  /** 403017=公用跳绳组内没有跳绳 */
  E_403017 = 403017,
  /** 403018=跳绳型号不一致 */
  E_403018 = 403018,
}
