import NextImage, { ImageProps as NextImageProps } from 'next/image';
import { ImageView2Options } from '@my/qiniu';
import type { SetOptional } from 'type-fest';
import { useQiniuSrc } from './qiniu';

export type ImageProps = SetOptional<NextImageProps, 'alt'> & {
  /** 七牛 imageView2 选项，参考 '@my/qiniu' */
  imageView2?: ImageView2Options;
  /**
   * 自动使用 imageView2，mode = 2，w = width, h = height;
   * 默认为 true
   */
  autoImageView2?: boolean;
};

export const Image = ({
  src,
  alt = '',
  imageView2: imageView2Options,
  autoImageView2,
  loader,
  ...props
}: ImageProps) => {
  const { width, height } = props;

  const newSrc = useQiniuSrc({ src, width, height, imageView2Options, autoImageView2 });
  const myLoader: ImageProps['loader'] =
    loader ??
    (({ src }) => {
      return src;
    });

  return <NextImage {...props} alt={alt} loader={myLoader} unoptimized src={newSrc} />;
};

export default Image;
