import { makeAutoObservable, runInAction } from 'mobx';
import TIM, { Conversation, Message } from 'tim-js-sdk';
import {
  createTextMessage,
  sendMessage,
  setReadMessage,
  getGroupProfile,
  createImageMessage,
  createVideoMessage,
} from '@shared/common/helper/IMSDK';
import { message as antdMessage, message } from 'antd';
import { RootStore } from '@my/mobx-rootstore';
import { getConfig } from './config';
import { UserSimpleDto, ImGroupMemberInfo, MemberRole } from '../type';

import defaultImg from '../assets/patient_img.png';
import dayjs from 'dayjs';
import { ApiResponseData, callApi } from '@/apis';

export type MessageListProps = Omit<
  ApiResponseData<'/im/gm-history', 'post'>['list'][number],
  'type' | 'payload' | 'groupId'
> & {
  type: string;
  payload: any;
  groupId?: string;
  to?: string;
};
export class ConversationStore {
  /** 会话ID */
  conversationId?: string = '';
  /** 是否单聊 */
  singleChat = false;
  /** 单聊的用户信息 */
  singleChatUserInfo?: UserSimpleDto = undefined;
  /** 医生id */
  doctorUserId?: string = undefined;
  /** 用户id */
  patientUserId?: string = undefined;
  /** 群组用户信息 */
  groupMemberInfo?: ImGroupMemberInfo = undefined;
  /** 当前群组信息 */
  groupProfile: any = {};
  /** 当前群组历史消息列表 */
  recordList: Message[] = [];
  /** 下一页信息列表的请求参数 */
  nextReqMessage?: {
    nextReqMessageID?: string;
    isCompleted?: boolean;
  } = { nextReqMessageID: '', isCompleted: false };
  /** 当前会话 */
  conversation?: Conversation = undefined;
  /** 输入框文本 */
  inputValue?: string = '';
  /** 接收到的新消息 */
  newMessage?: Message[] = [];
  /** 预览的图片地址 */
  preViewMediaUrl?: string = '';
  inputElementImage: HTMLInputElement | null | undefined = undefined;
  inputElementVideo: HTMLInputElement | null | undefined = undefined;
  /** 发送消息时间 */
  sendTime = new Date().getTime();

  /** 消息列表3.0.0 */
  messageListTable = {
    page: 1,
    pageSize: 30,
    groupId: '',
    list: [] as MessageListProps[],
    total: 0,
    lastTime: undefined as number | undefined,
    lastSeq: undefined as number | undefined,
  };

  playingUrl?: string;
  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  get config() {
    return getConfig(this.rootStore);
  }

  async init({
    conversationId,
    singleChat,
    doctorUserId,
    patientUserId,
  }: {
    conversationId: string;
    singleChat?: boolean;
    doctorUserId?: string;
    patientUserId?: string;
  }) {
    // 清除上一个聊天内容
    this.clear();

    if (!conversationId) {
      return;
    }
    this.conversationId = conversationId;
    this.singleChat = singleChat ?? false;
    this.doctorUserId = doctorUserId ?? '';
    this.patientUserId = patientUserId ?? '';
    // 未读消息设为已读
    await setReadMessage({ conversationID: singleChat ? `C2C${this.conversationId}` : `GROUP${this.conversationId}` });

    await this.getIMInfo();
    await this.getRecordList({});
  }

  clear() {
    this.conversationId = undefined;
    this.messageListTable.list = [];
    this.messageListTable.lastSeq = undefined;
    this.messageListTable.lastTime = undefined;
    this.singleChatUserInfo = undefined;
    this.groupMemberInfo = undefined;
  }

  /** 获取当前群组信息 */
  async getGroupProfile() {
    const resp = await getGroupProfile({ groupID: this.conversationId! });
    runInAction(() => {
      this.groupProfile = resp?.data.group;
      // console.log('this.groupProfile', this.groupProfile);
    });
  }
  /** 播放音频 */
  playAudio(url: string) {
    const audio = new Audio(url);
    audio.play();
    // 监听播放
    audio.onplay = () => {
      console.log('开始播放');
      this.playingUrl = url;
    };
    audio.onended = () => {
      console.log('播放结束');
      this.playingUrl = undefined;
    };
  }
  /** 获取历史消息 */
  async getRecordList({ isClear }: { isClear?: boolean }) {
    if (isClear) {
      this.messageListTable.list = [];
      this.messageListTable.lastSeq = undefined;
      this.messageListTable.lastTime = undefined;
    }
    if (this.singleChat) {
      await this.getPMHistory();
      return;
    }
    await this.getGMHistory();
  }
  /** 单聊历史 */
  async getPMHistory() {
    const data = {
      page: this.messageListTable.page,
      pageSize: this.messageListTable.pageSize,
      lastTime: this.messageListTable.lastTime,
      lastSeq: this.messageListTable.lastSeq,
      otherId: this.conversationId ?? '',
    };
    const resp = await callApi('/im/pm-history', 'post', {
      data,
    });
    if (resp.error) {
      message.error(resp.error.errMsg);
      return;
    }
    const list = resp.data?.list ?? [];
    runInAction(() => {
      this.messageListTable.list = [...list, ...this.messageListTable.list];
      this.messageListTable.total = resp.data?.total ?? 0;
      this.messageListTable.lastTime = resp.data?.curTime;
      this.messageListTable.lastSeq = resp.data.curSeq;
    });
  }
  /** 群聊历史 */
  async getGMHistory() {
    const data = {
      page: this.messageListTable.page,
      pageSize: this.messageListTable.pageSize,
      lastTime: this.messageListTable.lastTime,
      lastSeq: this.messageListTable.lastSeq,
      groupId: this.groupMemberInfo?.groupId ?? '',
    };
    const resp = await callApi('/im/gm-history', 'post', {
      data,
    });
    if (resp.error) {
      message.error(resp.error.errMsg);
      return;
    }
    const list = resp.data?.list ?? [];
    runInAction(() => {
      this.messageListTable.list = [...list, ...this.messageListTable.list];
      this.messageListTable.total = resp.data?.total ?? 0;
      this.messageListTable.lastTime = resp.data?.curTime;
      this.messageListTable.lastSeq = resp.data.curSeq;
    });
  }
  get recordUIDataList() {
    const newList = this.messageListTable.list
      .filter((message) => {
        return message.from !== '@TIM#SYSTEM';
      })
      .map((item) => {
        const ImageUI = item.payload.imageInfoArray?.find((item: { type: number }) => {
          return item.type === 2;
        });
        const isCustomMessage = item.type === TIM.TYPES.MSG_CUSTOM;
        let payload = item.payload;
        // 判断是否是自定义消息 并且是否是一个对象
        if (isCustomMessage && typeof item.payload.data === 'string') {
          payload.data = item.payload.data && JSON.parse(item.payload.data);
        }
        if (this.singleChat) {
          return {
            ...item,
            time: item.time * 1000,
            avatar:
              item.flow === 'out'
                ? this.config?.currentUserImg || defaultImg.src
                : this.singleChatUserInfo?.img || defaultImg.src,
            imageUrl: ImageUI?.url?.indexOf('blob:') !== -1 ? ImageUI?.imageUrl : ImageUI?.url,
            imageWidth: ImageUI?.width,
            imageHeight: ImageUI?.height,
            name: this.singleChatUserInfo?.name,
            payload,
            role: undefined,
          };
        }
        const uesrInfo = this.groupMemberInfo?.memberInfoList?.find((userInfo) => {
          if (userInfo.role === MemberRole.PATIENT) return userInfo.mainUserId === item.from;
          return userInfo.userId === item.from;
        });
        return {
          ...item,
          name: uesrInfo?.name,
          time: item.time * 1000,
          avatar: uesrInfo?.img || defaultImg.src,
          imageUrl: ImageUI?.url?.indexOf('blob:') !== -1 ? ImageUI?.imageUrl : ImageUI?.url,
          imageWidth: ImageUI?.width,
          imageHeight: ImageUI?.height,
          payload,
          role: uesrInfo?.role,
        };
      });
    return newList;
  }

  /** 获取会话名称 */
  get ConversationName() {
    if (this.singleChat) {
      return this.singleChatUserInfo?.name;
    }
    return this.groupMemberInfo?.memberInfoList?.find((item) => {
      return item.role === MemberRole.PATIENT;
    })?.name;
  }

  get patientId() {
    return this.groupMemberInfo?.memberInfoList?.find((item) => {
      return item.role === MemberRole.PATIENT;
    })?.userId;
  }

  /** 获取input文本信息 */
  getInputValue(valve: string) {
    this.inputValue = valve;
  }

  /** 发送文本消息 */
  async sendTextMessage() {
    if (!this.inputValue?.trim()) {
      antdMessage.warn('发送内容不能为空');
      return;
    }
    // 防止重复发送
    const now = new Date().getTime();
    if (now - this.sendTime <= 500) {
      return;
    }
    this.sendTime = now;
    this.doctorSendRecord();
    const messageInfo: any = await createTextMessage({
      to: this.conversationId!,
      conversationType: this.singleChat ? TIM.TYPES.CONV_C2C : TIM.TYPES.CONV_GROUP,
      content: this.inputValue,
    });
    await sendMessage(messageInfo!);
    runInAction(() => {
      this.inputValue = undefined;
      this.messageListTable.list.push({
        id: messageInfo.ID,
        ...messageInfo,
        // time: new Date().getTime() / 1000,
        // avatar: this.AuthStore.currentUser?.img || defaultImg.src,
      });
    });
  }

  /** 预览图片 */
  preViewMdeia(url: string) {
    this.preViewMediaUrl = url;
  }

  clearPreViewMdeia() {
    // 清除上个预览的图片
    this.preViewMediaUrl = undefined;
  }

  /** 发送图片或者视频 */
  chooseMedia({ type }: { type: 'video' | 'image' }) {
    if (type === 'image') {
      this.inputElementImage?.click();
      return;
    }
    this.inputElementVideo?.click();
  }

  /** 图片INPUT */
  inputElement({ Element, type }: { Element: HTMLInputElement | null; type: 'video' | 'image' }) {
    if (type === 'image') {
      this.inputElementImage = Element;
      return;
    }
    this.inputElementVideo = Element;
  }
  /** 医生发消息记录 */
  async doctorSendRecord() {
    if (this.singleChat) return;
    if (!this.config.callDoctorSendRecord) {
      throw new Error(`this.config.callDoctorSendRecord 接口为空`);
    }
    const resp = await this.config.callDoctorSendRecord({
      doctorUserId: this.doctorUserId ?? '',
      patientUserId: this.patientId ?? this.patientUserId ?? '',
      sendTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    });
    if (resp.error) {
      console.error(resp.error?.errMsg);
      return;
    }
  }
  // 创建图片信息
  async getFiles({ enent, type }: { enent: any; type: 'video' | 'image' }) {
    const _file: File[] = Array.from(enent.target.files);
    if (_file.length > 9) {
      antdMessage.warn(`最多只能选择9${type === 'image' ? '张图片' : '个视频'}`);
      return;
    }
    if (type === 'video') {
      const exceed = this.checkFileSize({ file: _file, fileMaxSize: 100 });
      if (exceed.length) return;
    }
    _file.forEach((_, i) => {
      this.sendMediaMessage({ file: enent.target.files[i], type });
    });
  }

  async sendMediaMessage({ file, type }: { file: Object | HTMLInputElement | File; type: 'image' | 'video' }) {
    const key = new Date().valueOf();
    antdMessage.loading({ content: `正在发送中,请稍后...`, key, duration: 0 });
    console.log('file 文件提交', file);
    const conversationType = this.singleChat ? TIM.TYPES.CONV_C2C : TIM.TYPES.CONV_GROUP;
    this.doctorSendRecord();

    const message: any =
      type === 'image'
        ? await createImageMessage({
            to: this.conversationId!,
            conversationType,
            file: file,
            onProgress: (e) => {
              antdMessage.loading({ content: `已发送${(e * 100).toFixed(0)}%,请稍候...`, key, duration: 0 });
            },
          }).catch((error) => {
            antdMessage.loading({ content: `发送失败,请检查网络是否正常`, key });
            console.error(error);
          })
        : await createVideoMessage({
            to: this.conversationId!,
            conversationType,
            file: file,
            onProgress: (e) => {
              antdMessage.loading({ content: `已发送${(e * 100).toFixed(0)}%,请稍候...`, key, duration: 0 });
            },
          }).catch((error) => {
            antdMessage.loading({ content: `发送失败,请检查网络是否正常`, key });
            console.error(error);
          });
    await sendMessage(message!).catch(() => {
      antdMessage.error(`${type === 'image' ? '图片' : '视频'}发送失败,请检查网络是否正常`);
    });
    console.log('发送完成', message);
    // 发送完成关闭信息
    antdMessage.destroy(key);
    this.messageListTable.list.push({
      id: message?.ID,
      ...message,
    });
    // await this.getRecordList({ isClear: true });
  }

  /** 检测是否超大 */
  checkFileSize({ file, fileMaxSize }: { file: File[]; fileMaxSize: number }) {
    const imageMaxSize = 1048576 * fileMaxSize;
    const exceed: number[] = [];
    file.forEach((item, i) => {
      if (item.size >= imageMaxSize) exceed.push(i);
    });
    if (exceed.length) {
      const exceedNumber = exceed?.map((item) => {
        return item + 1;
      });
      antdMessage.warn(
        exceed.length > 1
          ? `第${[...exceedNumber]}个超过${fileMaxSize}mb,无法发送`
          : `视频超过${fileMaxSize}mb,无法发送`,
      );
    }
    return exceed;
  }

  /** 获取IM用户信息 */
  async getIMInfo() {
    if (this.singleChat) {
      await this.getIMUserInfo();
      return;
    }
    await this.getGroupProfile();
    await this.getIMGroupInfo();
  }

  /** 获取单聊的用户信息 */
  async getIMUserInfo() {
    if (!this.conversationId) {
      return;
    }
    const data = { userIds: [this.conversationId] };
    const resp = await this.config.CallIMUserInfoApi(data);
    if (resp.error) {
      antdMessage.error('获取用户信息失败');
      return;
    }
    runInAction(() => {
      this.singleChatUserInfo = resp.data[0];
    });
  }

  /** 获取群组的用户信息 */
  async getIMGroupInfo() {
    if (!this.conversationId) {
      return;
    }
    const data = {
      groupIds: [this.conversationId!],
    };
    const resp = await this.config.CallIMGroupInfoApi(data);
    if (resp.error) {
      antdMessage.error('获取用户信息失败');
      return;
    }

    runInAction(() => {
      this.groupMemberInfo = resp.data[0];
    });
  }
}
